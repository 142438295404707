.full-width-page-teaser {
  background-size: cover;
  background-position-x: center;

  .container {
    padding-top: 10rem;
    padding-bottom: 10rem;

    @include screen-down(md) {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem;
    }
  }

  &__whitebox {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
    text-align: left;
    padding: 2.5rem 3rem;
    margin: 0 auto;
    max-width: 66%;

    @include screen-down(md) {
      padding: 2rem;
      max-width: none;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: $font-weight-medium;
  }

  &__content {
    font-size: 1rem;
    font-weight: 400;
  }
}
