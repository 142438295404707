@each $bp, $value in $breakpoints {
  @include screen-down($bp) {
    .u-#{$bp}-down-hidden {
      display: none !important;
    }
  }

  @include screen-up($bp) {
    .u-#{$bp}-up-hidden {
      display: none !important;
    }
  }
}
