$form-padding: 5rem 1rem;
$form-padding-sm: 2.5rem 1rem;

.contact-form {
  padding: $form-padding;
  margin: 0 auto;
  max-width: $content-area-width;

  @include screen-down(md) {
    padding: $form-padding-sm;
  }

  &__image {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    overflow: hidden;

    @include screen-down(sm) {
      margin-bottom: 1.5rem;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__tag {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-medium;
    color: color('black');
    font-size: $font-size-sm;
    line-height: 1.5;
    margin: 0;
  }

  &__name {
    font-size: nth($headings-font-size-lg, 4);
    line-height: 1.5;
    font-weight: $font-weight-medium;
    opacity: 1;
    margin-bottom: 0.5em;
    color: color('blue');

    @include screen-down(sm) {
      font-size: nth($headings-font-size-sm, 4);
      line-height: nth($headings-line-height-sm, 4);
    }
  }

  &__text {
    margin-bottom: 1.5rem;
    margin-top: 0;
    opacity: 1;
    font-size: nth($headings-font-size-lg, 2);
    line-height: 1.5;
    font-weight: $font-weight-medium;

    @include screen-down(sm) {
      font-size: nth($headings-font-size-sm, 2);
      line-height: nth($headings-line-height-sm, 2);
    }
  }

  &__contact-information {
    display: inline-flex;

    .button {
      display: -webkit-box; // stylelint-disable-line
    }
  }

  &__email,
  &__phone,
  &__whatsapp {
    padding-right: 3rem;
  }
}

// legacy form (from Magnolia)
.contact-form__form,
.global-contact-form {
  margin: $margin-3 auto;
  color: color('blue');

  .form {
    background-color: color('white');
  }

  #mgnlhp {
    @include sr-only;
  }

  dfn[title='required'] {
    display: none;
  }

  fieldset {
    display: flex;
    justify-content: flex-start;
    width: 66.6%;

    @include screen-down(md) {
      width: 100%;
    }
  }

  fieldset:first-of-type {
    width: 100%;
  }

  fieldset:last-of-type {
    width: 100%;

    .form-row {
      width: 66.6%;

      @include screen-down(md) {
        width: 100%;
      }
    }
  }

  fieldset:not(:first-of-type) {
    display: none;

    /* stylelint-disable-next-line */
    &.contact-form-open {
      display: flex;

      @include screen-down(md) {
        display: block;
      }
    }

    label {
      display: none;
    }
  }

  .form-row {
    margin: $margin-2 $margin-3;
    display: inline-flex;
    width: 50%;

    &:only-child {
      width: 100%;
    }

    @include screen-down(md) {
      width: 100%;
      margin: 0;
      padding: $margin-2 $margin-3;
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
  }

  .button-wrapper {
    margin: 0 auto;

    @include screen-down(md) {
      margin-bottom: $margin-2;
      padding-right: $margin-3;
      display: flex;
      justify-content: flex-end;
    }
  }

  .success {
    text-align: center;

    h1 {
      font-size: 1.5rem;
      line-height: 1.33;
    }

    p {
      font-size: 1.25rem;
    }
  }

  .error {

    h1 {
      font-size: 1.5rem;
      line-height: 1.33;
    }

    a {
      font-weight: 400;
      color: color('black');
    }

    .link {
      opacity: 1;
    }
  }

  /* styling for checkbox input */
  /* stylelint-disable selector-no-qualifying-type */

  fieldset label.checkbox {
    display: inline-flex;
  }

  /* stylelint-enable selector-no-qualifying-type */

  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    align-items: center;

    a,
    p {
      color: color('black');
      padding: 0 0.25rem;
      line-height: 1.125rem;
      font-size: $font-size-base;
      font-weight: $font-weight-base;
      margin: 0;

      @include screen-down(md) {
        font-size: $font-size-sm;
      }

      @include screen-down(xs) {
        hyphens: auto;
      }
    }

    &__input {
      display: none;

      &:checked ~ .checkbox__unchecked {
        display: none;
      }

      &:checked ~ .checkbox__checked {
        display: block;
      }

      &:not(:checked) ~ .checkbox__unchecked {
        display: block;
      }

      &:not(:checked) ~ .checkbox__checked {
        display: none;
      }
    }
  }

  .alert a {
    text-decoration: underline;
  }
}

// Microsoft Dynamics form
// stylelint-disable
.contact-form [data-form-block-id],
.contact-form [data-form-id] {
  margin: $margin-3 auto;

  .formLoader {
    border-top: 16px solid color('blue');
  }

  form {
    .marketing-field textarea {
      color: color('black');

      &::placeholder {
        color: color('blue');
      }

    }

    [data-layout] {
      max-width: initial;
      background-color: color('white');
      color: color('black');
    }

    [data-section] {
      &:nth-child(2),
      &:nth-child(3) {
        max-width: 800px;
      }
    }

    label {
      display: none;
    }

    [data-editorblocktype='Field-checkbox'],
    [data-editorblocktype='TwoOptionFormField'] {
      .lp-form-field {
        display: flex;
        align-items: center;
      }

      label {
        display: inline-block;
        margin: 0;
      }
    }

    [data-editorblocktype='SubmitButtonBlock'],
    [data-editorblocktype='SubmitButton'] {
      display: flex;
      align-items: center;
      justify-content: right;

      p {
        opacity: 1;
        margin: 0;
      }

      button {
        @extend .button;
        background-color: color('blue') !important;
        color: color('white') !important;
        padding: 0.625em 2em;
      }
    }
  }
}

.section--bg-gradient .contact-form .lp-form-field .lp-ellipsis a,
.section--bg-dark .contact-form .lp-form-field .lp-ellipsis a,
.section--bg-blue-dark .contact-form .lp-form-field .lp-ellipsis a,
.section--bg-gradient .contact-form .lp-form-field .lp-ellipsis a:hover,
.section--bg-dark .contact-form .lp-form-field .lp-ellipsis a:hover,
.section--bg-blue-dark .contact-form .lp-form-field .lp-ellipsis a:hover {
  color: inherit;
  text-decoration: none;
}

.section--bg-gradient .contact-form .lp-form-field .lp-ellipsis a:hover,
.section--bg-dark .contact-form .lp-form-field .lp-ellipsis a:hover,
.section--bg-blue-dark .contact-form .lp-form-field .lp-ellipsis a:hover {
  text-decoration: underline;
}

//overwrite the success-form from
.contact-form div[data-form-block-id] .onFormSubmittedFeedback,
.contact-form div[data-form-id] .onFormSubmittedFeedback {
  width: auto !important;
  height: auto !important;

  .onFormSubmittedFeedbackInternalContainerStyle,
  .onFormSubmittedFeedbackInternalContainer {
    padding: $form-padding;

    @include screen-down(md) {
      padding: $form-padding-sm;
    }

    .onFormSubmittedFeedbackIcon {
      display: none !important;
    }

    .onFormSubmittedFeedbackMessage {
      font-family: $font-family-base;
      font-size: 1rem;
      padding-bottom: 0;
    }

    .onFormSubmittedFeedbackButton {
      display: none;
    }
  }
}

//overwrite the error-message
.contact-form div[data-form-block-id] .dialogProviderContainerStyle,
.contact-form div[data-form-id] .dialogProviderContainerStyle {
  width: 100% !important;
  height: auto !important;
  position: relative;

  .dialogProviderInternalContainerStyle {
    position: relative;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}
