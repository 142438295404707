@import './main-menu/quick-links';
@import './main-menu/language-selector';
@import './main-menu/nav';
@import './main-menu/page-highlight';
@import './main-menu/sub-nav';
@import './main-menu/menu-list';
@import './main-menu/mobile-menu';

.main-menu {
  $dropdown-duration: 300ms;
  $dropdown-timing: cubic-bezier(0.15, 0.89, 0.38, 1);
  $highlight-width: 25%;

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: $menu-bar-height;
  z-index: 10;
  background: color('white');
  transition: background-color $dropdown-duration $dropdown-timing, color $dropdown-duration $dropdown-timing;
  color: color('black');

  @include screen-down(md) {
    height: $menu-bar-height-mobile;
  }

  &__meta-nav {
    padding: 0 2.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 25%;
    font-size: 1rem;
    font-weight: $font-weight-normal;

    @include screen-down(md) {
      display: none;
    }

    ul {
      display: flex;
    }

    li {
      padding: 0 1em;
    }

    &-languages {
      margin-left: 4em;
    }
  }

  &__nav {
    height: 75%;
    border-bottom: 1px solid color('blue-light');

    @include screen-down(md) {
      height: 100%;
    }

    > * {
      height: 100%;
    }
  }

  &__dropdown {
    transition: transform $dropdown-duration $dropdown-timing;
    transform-origin: 50% 0;
    transform: scaleY(0);
    height: calc(90% - #{$menu-bar-height});
    max-height: 40rem;
    background: white;
    border-bottom: 1px solid color('blue-light');
    display: flex;
    position: fixed;
    top: #{$menu-bar-height};
    left: 0;
    right: 0;
    z-index: inherit;

    @include screen-down(md) {
      top: #{$menu-bar-height-mobile};
      height: calc(100% - #{$menu-bar-height-mobile});
      max-height: none;
    }

    &-content {
      width: calc(100% - #{$highlight-width});
      overflow: hidden;
      height: 100%;

      @include screen-down(md) {
        width: 100%;
      }

      > * {
        height: inherit;
      }
    }
  }

  &__highlight-container {
    width: $highlight-width;
    height: 100%;

    @include screen-down(md) {
      display: none;
    }

    > * {
      height: inherit;
    }
  }

  &--transparent {
    background: transparent;

    .main-menu__nav {
      border-bottom-color: transparent;

      *:not(path) {
        color: color('white');
      }
    }

    .nav__links-list-item {

      .js-nav-button:hover::after,
      .js-nav-button.is-active::after {
        background-color: color('white');
      }
    }

    .ti8m-logo {
      color: color('white');
    }

    .main-menu__meta-nav a {
      color: color('white');
    }

    &.bg-color--light,
    &.bg-color--blue-light,
    &.bg-color--grey {

      .main-menu__nav {

        *:not(.ti8m-logo):not(path) {
          color: color('black');
        }
      }

      .nav__links-list-item {

        .js-nav-button:hover::after,
        .js-nav-button.is-active::after {
          background-color: color('blue');
        }
      }

      .ti8m-logo {
        color: color('blue');
      }
    }

    &.bg-color--green {

      .main-menu__nav {

        * {
          color: color('black');
        }
      }

      .nav__links-list-item {

        .js-nav-button:hover::after,
        .js-nav-button.is-active::after {
          background-color: color('black');
        }
      }
    }
  }

  &--open {

    .main-menu__dropdown {
      transform: scaleY(1);
    }

    .nav__hamburger-button {

      .svg-icon--close {
        display: initial;
      }

      .svg-icon--hamburger {
        display: none;
      }
    }
  }

  &--with-mobile-back-button {
    @include screen-down(md) {

      .nav__logo {
        display: none;
      }

      .mobile-menu-back-button {
        display: block;
      }
    }
  }

  .ti8m-logo {
    color: color('blue');
  }

  .search-button {
    border: none;
    position: absolute;
    right: 1rem;
    z-index: 2;

    path {
      opacity: 0.5;
    }
  }

  @include screen-down(md) {

    .search-container {
      position: absolute;
      right: 0;
      left: 0;
      top: 0;

      .search-form {
        padding: 1rem;
        background-color: color('white');
        min-width: 270px;
        min-height: 70px;
        border: 1px solid color('blue-light');
        z-index: 11;
      }

      .search-input {
        padding: 0;
        width: 100%;
        border: none;
        outline: none;
        border-bottom: 3px solid color('blue');
        color: color('black');
        z-index: 1;
      }
    }
  }

  @include screen-up(md) {

    .search-container {
      position: relative;

      .search-form {
        position: absolute;
        right: 0;
        top: 2rem;
        padding: 1rem;
        background-color: color('white');
        min-width: 270px;
        min-height: 70px;
        border: 1px solid color('blue-light');
        z-index: 11;
      }

      .search-input {
        padding: 0;
        width: 100%;
        border: none;
        outline: none;
        border-bottom: 3px solid color('blue');
        color: color('black');
        z-index: 1;
      }
    }
  }
}
