.process {
  @include screen-up(md) {
    margin: 0 80px;
  }

  .process__wrapper {
    display: block;
    margin: 2rem;
    max-width: 1120px;
    border-left: 3px solid color('blue-dark');
    padding: 0 1rem 0 calc(1rem - 2.5px);

    @include screen-up(md) {
      margin: 80px auto;
    }

    .step__wrapper {
      scroll-margin-top: 120px;
    }

    .step-title {
      font-size: 1.25rem;
      line-height: 2rem;
      margin: 0;
      padding-bottom: 16px;
      padding-left: 24px;

      &.active {

        &::before {
          border: 1px solid #E0EEF3;
          box-shadow: 0 0 0 4px color('white');
          min-height: 32px;
          top: 0;
        }

        &::after {
          min-height: 32px;
          top: 0;
        }
      }

      &::before {
        content: '';
        background-color: color('white');
        min-height: 16px;
        min-width: 32px;
        position: absolute;
        left: -16px;
        top: 8px;
        border-radius: 50%;
      }

      &::after {
        content: '';
        min-height: 32px;
        min-width: 32px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nNicgaGVpZ2h0PSc2JyB2aWV3Qm94PScwIDAgNiA2JyBmaWxsPSdub25lJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPjxjaXJjbGUgY3g9JzMnIGN5PSczJyByPSczJyBmaWxsPSdibGFjaycgZmlsbC1vcGFjaXR5PScwLjgnLz48L3N2Zz4=');
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: -16px;
        text-align: center;
      }
    }

    .step-text {
      font-size: 1rem;
      opacity: 0.6;
      padding-left: 24px;
      line-height: $line-height-lg;
    }

    .link-title {
      font-weight: 500;
      margin-bottom: 8px;
      opacity: 1;
      padding-left: 24px;
      font-size: 1rem;
      line-height: 1.5rem;

      @include screen-up(md) {
        padding-left: 0;
      }
    }

    .quick-links {
      padding-left: 24px;

      @include screen-up(md) {
        padding-left: 0;
      }

      li {
        list-style-type: none;
        margin: 0;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAnIGhlaWdodD0nMjAnIHZpZXdCb3g9JzAgMCAyMCAyMCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAuMDAwMyAxOC45NTc3QzUuMDU4NjYgMTguOTU3NyAxLjA0MTk5IDE0Ljk0MSAxLjA0MTk5IDkuOTk5MzVDMS4wNDE5OSA1LjA1NzY4IDUuMDU4NjYgMS4wNDEwMiAxMC4wMDAzIDEuMDQxMDJDMTQuOTQyIDEuMDQxMDIgMTguOTU4NyA1LjA1NzY4IDE4Ljk1ODcgOS45OTkzNUMxOC45NTg3IDE0Ljk0MSAxNC45NDIgMTguOTU3NyAxMC4wMDAzIDE4Ljk1NzdaTTEwLjAwMDMgMi4yOTEwMkM1Ljc1MDMzIDIuMjkxMDIgMi4yOTE5OSA1Ljc0OTM1IDIuMjkxOTkgOS45OTkzNUMyLjI5MTk5IDE0LjI0OTMgNS43NTAzMyAxNy43MDc3IDEwLjAwMDMgMTcuNzA3N0MxNC4yNTAzIDE3LjcwNzcgMTcuNzA4NyAxNC4yNDkzIDE3LjcwODcgOS45OTkzNUMxNy43MDg3IDUuNzQ5MzUgMTQuMjUwMyAyLjI5MTAyIDEwLjAwMDMgMi4yOTEwMlonIGZpbGw9J2JsYWNrJy8+PHBhdGggZD0nTTEyLjA4MzcgMTAuNjI0M0g3LjA4MzY2QzYuNzQxOTkgMTAuNjI0MyA2LjQ1ODY2IDEwLjM0MSA2LjQ1ODY2IDkuOTk5MzVDNi40NTg2NiA5LjY1NzY4IDYuNzQxOTkgOS4zNzQzNSA3LjA4MzY2IDkuMzc0MzVIMTIuMDgzN0MxMi40MjUzIDkuMzc0MzUgMTIuNzA4NyA5LjY1NzY4IDEyLjcwODcgOS45OTkzNUMxMi43MDg3IDEwLjM0MSAxMi40MjUzIDEwLjYyNDMgMTIuMDgzNyAxMC42MjQzWicgZmlsbD0nYmxhY2snLz48cGF0aCBkPSdNMTAuNDE2NiAxMy4xMjQzQzEwLjI1ODIgMTMuMTI0MyAxMC4wOTk5IDEzLjA2NTkgOS45NzQ5MSAxMi45NDA5QzkuNzMzMjQgMTIuNjk5MyA5LjczMzI0IDEyLjI5OTMgOS45NzQ5MSAxMi4wNTc2TDEyLjAzMzIgOS45OTkyN0w5Ljk3NDkxIDcuOTQwOTdDOS43MzMyNCA3LjY5OTMgOS43MzMyNCA3LjI5OTMgOS45NzQ5MSA3LjA1NzYzQzEwLjIxNjYgNi44MTU5NyAxMC42MTY2IDYuODE1OTcgMTAuODU4MiA3LjA1NzYzTDEzLjM1ODIgOS41NTc2QzEzLjU5OTkgOS43OTkyNyAxMy41OTk5IDEwLjE5OTMgMTMuMzU4MiAxMC40NDA5TDEwLjg1ODIgMTIuOTQwOUMxMC43MzMyIDEzLjA2NTkgMTAuNTc0OSAxMy4xMjQzIDEwLjQxNjYgMTMuMTI0M1onIGZpbGw9J2JsYWNrJy8+PC9zdmc+Cg==');
        background-position: left 4px;
        background-repeat: no-repeat;
        padding: 0 0 0 28px;
        font-size: 1rem;
        line-height: 1.5rem;

        a {
          font-weight: 500;
          line-height: 28px;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .accordion-btn {
      cursor: pointer;
      text-align: left;
      border: none;
      outline: none;
      transition: 0.4s;
      background-color: transparent;

      &.show {
        rotate: 180deg;
      }

      @include screen-up('md') {
        display: none;
      }
    }

    .accordion-body {
      padding: 0 18px;
      display: none;
      overflow: hidden;

      &.open {
        display: flex;
      }

      @include screen-up('md') {
        display: flex;
      }
    }
  }
}
