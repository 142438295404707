.download-form {

  &__wrapper {
    padding-top: $margin-4;
    margin: 0 auto;
    max-width: $content-area-width;
  }

  &__image {
    padding: 1rem 0;
    display: grid;
    place-items: baseline self-start;

    @include screen-down(sm) {
      place-items: baseline center;
    }

    img {
      max-height: 600px;
      max-width: 100%;
      align-self: center;
    }
  }

  &__html {
    max-width: 100%;
    text-align: left;

    // stylelint-disable
    // the default styling of the download dynamics realtime form
    [data-form-id] .formLoader {
      border-top: 16px solid color('blue');
    }
    .editor-control-layout html {
      box-sizing: border-box;
      background-color: color('white');
    }
    .editor-control-layout *,
    .editor-control-layout *:before,
    .editor-control-layout *:after {
      box-sizing: inherit;
    }
    .marketingForm
      *:not(
        .inlineOverlay *,
        .emptySectionPlaceholder *,
        .designer-overlay__content *
      ) {
      font-family: $font-family-sans-serif;
    }
    .zero-state-container span {
      font-family: $font-family-sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: color('black');
    }
    form.marketingForm {
      font-size: 14px;
      color: color('black');
    }
    [data-layout="true"] {
      margin: 0 auto;
      max-width: 600px;
    }
    div[data-editorblocktype="SubmitButton"] {
      padding: 20px 12%;
    }
    button.submitButton {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      border: none;
      border-radius: 4px;
      padding: 10px 20px;
      cursor: pointer;
    }
    div[data-editorblocktype="Captcha"] {
      padding: 20px 12%;
    }
    div[data-editorblocktype="Captcha"] table,
    div[data-editorblocktype="Captcha"]
      div
      > table
      > tbody
      > tr
      > td:nth-of-type(2) {
      width: 100% !important;
    }
    div[data-editorblocktype="Captcha"]
      div
      > div
      > table
      > tbody
      > tr:first-of-type
      > td:last-of-type {
      padding-bottom: 12px !important;
    }
    div[data-editorblocktype="Captcha"] tr[id="wlspispHIPErrorContainer"] {
      margin-top: 20px;
    }
    div[data-editorblocktype="Captcha"]
      tr[id="wlspispHIPErrorContainer"]
      td:nth-of-type(2) {
      width: auto !important;
    }
    div[data-editorblocktype="Captcha"] tr[id="wlspispHIPErrorContainer"] img {
      display: flex;
    }
    div[data-editorblocktype="Captcha"] td:nth-of-type(2) > div > div {
      margin-top: 4px;
    }
    div[data-editorblocktype="Captcha"] td:nth-of-type(2) > div > div * {
      font-weight: 400;
    }
    div[data-editorblocktype="Captcha"] td:nth-of-type(2) > div > div b {
      color: #797775;
    }
    div[data-editorblocktype="Captcha"] td:nth-of-type(2) a {
      font-size: 14px;
      line-height: 20px;
      color: color('blue');
    }
    div[data-editorblocktype="Captcha"] tr:nth-of-type(2) > td {
      height: 0px !important;
    }
    div[data-editorblocktype="Captcha"] td input {
      margin-top: 20px !important;
      padding: 6px 8px !important;
      border: 1px solid color('grey') !important;
      width: 100% !important;
    }
    div[data-editorblocktype="Captcha"] div[id^="wlspaudioBtnHolder"] {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    div[data-editorblocktype="About"],
    div[data-editorblocktype="Sessions"],
    div[data-editorblocktype="Speakers"] {
      padding: 32px 24px;
    }
    .textFormFieldBlock,
    .dateTimeFormFieldBlock,
    .twoOptionFormFieldBlock,
    .optionSetFormFieldBlock,
    .multiOptionSetFormFieldBlock,
    .lookupFormFieldBlock {
      padding: 20px 30px;
    }

    table.multi .textFormFieldBlock,
    table.multi .dateTimeFormFieldBlock,
    table.multi .twoOptionFormFieldBlock,
    table.multi .optionSetFormFieldBlock,
    table.multi .multiOptionSetFormFieldBlock,
    table.multi .lookupFormFieldBlock {
      padding: 12px 10px;
    }
    .consentBlock {
      padding: 4px 30px;
    }
    .textFormFieldBlock label,
    .dateTimeFormFieldBlock label,
    .lookupFormFieldBlock label,
    .twoOptionFormFieldBlock label.block-label,
    .optionSetFormFieldBlock label.block-label,
    .multiOptionSetFormFieldBlock label.block-label,
    div[data-editorblocktype="Captcha"]
      label[id^="wlspispHipInstructionContainer"] {
      font-size: 1rem;
      font-weight: 600;
      color: color('black');
    }
    .textFormFieldBlock label > *,
    .dateTimeFormFieldBlock label > *,
    .lookupFormFieldBlock label > *,
    .twoOptionFormFieldBlock label.block-label > *,
    .optionSetFormFieldBlock label.block-label > *,
    .multiOptionSetFormFieldBlock label.block-label > *,
    .consentBlock label > * {
      display: inline-block;
    }
    .textFormFieldBlock label::after,
    .twooption_checkbox label::after,
    .dateTimeFormFieldBlock label::after,
    .lookupFormFieldBlock label::after,
    .twoOptionFormFieldBlock label.block-label::after,
    .twoOptionFormFieldBlock div.twooption_checkbox label::after,
    .optionSetFormFieldBlock label.block-label::after,
    .multiOptionSetFormFieldBlock label.block-label::after,
    .consentBlock label::after,
    div[data-editorblocktype="Captcha"]
      label[id^="wlspispHipInstructionContainer"]::after {
      width: 12px;
      display: inline-block;
      line-height: 22px;
      text-align: center;
      color: #c33400;
      content: "*";
      visibility: hidden;
    }
    div[data-required].textFormFieldBlock label::after,
    div[data-required].dateTimeFormFieldBlock label::after,
    div[data-required].lookupFormFieldBlock label::after,
    div[data-required] div.twooption_checkbox label::after,
    div[data-required] label.block-label::after,
    div[data-required="true"].consentBlock label::after,
    div[data-editorblocktype="Captcha"]
      label[id^="wlspispHipInstructionContainer"]::after {
      visibility: visible;
    }
    div[data-hide].textFormFieldBlock,
    div[data-hide].dateTimeFormFieldBlock,
    div[data-hide].lookupFormFieldBlock,
    div[data-hide].twoOptionFormFieldBlock,
    div[data-hide].optionSetFormFieldBlock,
    div[data-hide].multiOptionSetFormFieldBlock {
      background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGFzc05hbWU9ImhpZGRlbkZpZWxkSWNvbiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0nMjQnIGhlaWdodD0nMjQnIHZpZXdCb3g9IjAgMCAyMDQ4IDIwNDgiPg0KICAgICAgICA8cGF0aCBkPSJNNzQgMjkybDkwLTkwIDE2MzAgMTYyOS05MSA5MS00NTctNDU3cS01NCAzNS0xMDUgNTN0LTExNyAxOHEtODAgMC0xNTAtMzB0LTEyMi04Mi04Mi0xMjItMzAtMTUwcTAtNjUgMTgtMTE2dDUzLTEwNkwzOTEgNjEwUTI2NiA3MTUgMTk3IDg1MXQtNjkgMzAxSDBxMC05MSAyMS0xNzl0NjAtMTcwIDk0LTE1MyAxMjYtMTMwTDc0IDI5MnptNjk0IDg2MHEwIDUzIDIwIDk5dDU1IDgyIDgxIDU1IDEwMCAyMHEzNiAwIDY3LTl0NjItMjdsLTM0OS0zNDlxLTE3IDMxLTI2IDYydC0xMCA2N3ptMzI4LTI0NUw5NjMgNzc0bDMwLTRxMTUtMiAzMS0yIDc5IDAgMTQ5IDMwdDEyMiA4MiA4MyAxMjMgMzAgMTQ5cTAgMTUtMiAzMHQtNCAzMWwtMTMzLTEzM3EtNDItMTMxLTE3My0xNzN6bTk1MiAyNDVoLTEyOHEwLTExOC0zNi0yMjF0LTk5LTE4OC0xNTAtMTUyLTE4NS0xMTMtMjA4LTcwLTIxOC0yNHEtOTggMC0xOTIgMTl0LTE4NSA1NmwtOTgtOThxMTE2LTUzIDIzMS03OXQyNDQtMjZxMTQ0IDAgMjg1IDM1dDI2NSAxMDUgMjI2IDE3MCAxNjYgMjM0cTQwIDgyIDYxIDE3MXQyMSAxODF6Ig0KICAgICAgICBmaWxsPSIjQzMzNDAwIiAvPg0KICAgICAgICA8L3N2Zz4=);
      background-repeat: no-repeat;
      background-position-x: 86%;
      background-position-y: 20%;
    }
    .textFormFieldBlock input,
    .dateTimeFormFieldBlock input,
    .lookupFormFieldBlock input,
    .marketingForm textarea,
    div[data-editorblocktype="Captcha"] input {
      margin-top: 16px;
      padding: 6px 8px;
      background: color('grey');
      border: 1px solid color('grey');
      border-radius: 2px;
      display: block;
      width: 100%;
      height: 32px;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 20px;
    }
    .textFormFieldBlock textArea {
      resize: vertical;
      height: 120px;
    }
    .lookupFormFieldBlock input {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMDQ4IDIwNDgiPjxwYXRoIGQ9Ik0xMzQ0IDBxOTcgMCAxODcgMjV0MTY4IDcxIDE0MiAxMTAgMTExIDE0MyA3MSAxNjggMjUgMTg3cTAgOTctMjUgMTg3dC03MSAxNjgtMTEwIDE0Mi0xNDMgMTExLTE2OCA3MS0xODcgMjVxLTEyNSAwLTIzOS00MnQtMjExLTEyMWwtNzg1IDc4NHEtMTkgMTktNDUgMTl0LTQ1LTE5LTE5LTQ1cTAtMjYgMTktNDVsNzg0LTc4NXEtNzktOTYtMTIxLTIxMHQtNDItMjQwcTAtOTcgMjUtMTg3dDcxLTE2OCAxMTAtMTQyVDk4OSA5NnQxNjgtNzEgMTg3LTI1em0wIDEyODBxMTE5IDAgMjI0LTQ1dDE4My0xMjQgMTIzLTE4MyA0Ni0yMjRxMC0xMTktNDUtMjI0dC0xMjQtMTgzLTE4My0xMjMtMjI0LTQ2cS0xMTkgMC0yMjQgNDVUOTM3IDI5NyA4MTQgNDgwdC00NiAyMjRxMCAxMTkgNDUgMjI0dDEyNCAxODMgMTgzIDEyMyAyMjQgNDZ6Ii8+PC9zdmc+);
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-position-y: center;
      background-size: 1em 1em;
    }
    .optionSetFormFieldBlock select {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02IDYuNzA4OThMMC4xNDY0ODQgMC44NTU0NjlMMC44NTU0NjkgMC4xNDY0ODRMNiA1LjI5MTAyTDExLjE0NDUgMC4xNDY0ODRMMTEuODUzNSAwLjg1NTQ2OUw2IDYuNzA4OThaIiBmaWxsPSIjNjA1RTVDIi8+Cjwvc3ZnPgo=);
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-position-y: center;
      appearance: none;
      margin-top: 16px;
      padding: 6px 8px;
      background-color: color('grey');
      border: 1px solid color('grey');
      border-radius: 2px;
      display: block;
      width: 100%;
    }
    .twoOptionFormFieldBlock div.radiobuttons,
    .twoOptionFormFieldBlock div.twooption_checkbox,
    .optionSetFormFieldBlock div.radiobuttons,
    .multiOptionSetFormFieldBlock fieldset {
      border: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
    }
    .twoOptionFormFieldBlock div.radiobuttons,
    .optionSetFormFieldBlock div.radiobuttons,
    .multiOptionSetFormFieldBlock fieldset {
      margin-top: 16px;
    }
    .twoOptionFormFieldBlock div.radiobuttons > div,
    .twoOptionFormFieldBlock div.twooption_checkbox > div,
    .optionSetFormFieldBlock div.radiobuttons > div,
    .multiOptionSetFormFieldBlock fieldset > div,
    .consentBlock > div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
    }
    .twoOptionFormFieldBlock div.radiobuttons div label,
    .twoOptionFormFieldBlock div.twooption_checkbox div label,
    .optionSetFormFieldBlock div.radiobuttons div label,
    .multiOptionSetFormFieldBlock fieldset > div > label,
    .consentBlock div label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      opacity: 0.7;

      p {
        opacity: 1;
      }
    }
    .twoOptionFormFieldBlock div.radiobuttons div input,
    .twoOptionFormFieldBlock div.twooption_checkbox div input,
    .optionSetFormFieldBlock div.radiobuttons div input,
    .multiOptionSetFormFieldBlock fieldset > div > input[type="checkbox"],
    .consentBlock div input {
      margin: 0px;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      border: 1px solid color('black');
      border-radius: 2px;
      accent-color: color('blue');
      flex-shrink: 0;
    }
    .marketingForm p {
      margin: 0px;
      padding: 0px;
      line-height: 125%;
      line-height: 1.25;
    }
    .marketingForm [dir="rtl"],
    .marketingForm [dir="rtl"] * {
      text-align: right;
    }
    .marketingForm s span {
      text-decoration: line-through;
    }
    .marketingForm u span {
      text-decoration: underline;
    }
    .dividerWrapper p span {
      line-height: 0px;
    }
    .marketingForm ul,
    .marketingForm ol {
      margin-top: 15px;
      margin-bottom: 15px;
      line-height: 125%;
      line-height: 1.25;
      padding-inline-end: 0px !important;
    }
    .marketingForm p img {
      max-width: 100%;
    }
    .marketingForm a {
      text-decoration: none;
    }
    .marketingForm u a {
      text-decoration: underline;
    }
    .marketingForm h1 {
      font-size: 28px;
    }
    .marketingForm h2 {
      font-size: 22px;
    }
    .marketingForm h3 {
      font-size: 18px;
    }
    .marketingForm h1,
    .marketingForm h2,
    .marketingForm h3,
    .marketingForm h4 {
      margin: 0px;
      color: color('black');
    }
    .marketingForm .imageWrapper a img {
      text-decoration: none;
      border: 0px;
    }
    .marketingForm th.columnContainer,
    .marketingForm th.inner {
      font-weight: normal;
      text-align: left;
    }
    .marketingForm [dir="rtl"] th.columnContainer,
    .marketingForm [dir="rtl"] th.inner {
      font-weight: normal;
      text-align: right;
    }
    @media only screen and (max-width: 768px) {
      .marketingForm .tbContainer {
        display: block !important;
      }
      .marketingForm a span {
        line-height: inherit !important;
      }
      .marketingForm .multi table {
        table-layout: fixed;
        height: auto !important;
      }
      .marketingForm .multi td {
        width: auto !important;
        min-height: auto !important;
      }
      .marketingForm .multi tbody,
      .marketingForm .multi tbody tr,
      .marketingForm .multi tbody tr td,
      .marketingForm .multi tbody tr th,
      .marketingForm .outer tbody,
      .marketingForm .outer tbody tr,
      .marketingForm .outer tbody tr td,
      .marketingForm .outer tbody tr th {
        display: block;
        box-sizing: border-box;
        height: auto !important;
        min-height: auto !important;
      }
      .marketingForm .wrap-section .multi tbody,
      .marketingForm .wrap-section .multi tbody tr,
      .marketingForm .wrap-section .multi tbody tr td,
      .marketingForm .wrap-section .multi tbody tr th,
      .marketingForm .wrap-section .outer tbody,
      .marketingForm .wrap-section .outer tbody tr,
      .marketingForm .wrap-section .outer tbody tr td,
      .marketingForm .wrap-section .outer tbody tr th {
        width: 100% !important;
      }
      .marketingForm .multi .inner {
        height: auto !important;
        min-height: auto !important;
      }
      .marketingForm .tbContainer .inner {
        box-sizing: border-box;
      }
      .marketingForm .outer {
        width: 100% !important;
      }
      .marketingForm .tbContainer .columnContainer table {
        table-layout: fixed !important;
      }
      .marketingForm .innerTable {
        min-height: auto !important;
      }
      .marketingForm .no-wrap-section .outer {
        display: table !important;
      }
      .marketingForm .no-wrap-section .multi table {
        height: 100% !important;
      }
      .marketingForm .no-wrap-section .multi tbody,
      .marketingForm .no-wrap-section .outer tbody {
        display: table-row-group !important;
        width: 100% !important;
      }
      .marketingForm .no-wrap-section .multi tbody tr,
      .marketingForm .no-wrap-section .outer tbody tr {
        display: table-row !important;
        width: 100% !important;
      }
      .marketingForm .no-wrap-section .multi tbody tr td,
      .marketingForm .no-wrap-section .multi tbody tr th,
      .marketingForm .no-wrap-section .outer tbody tr td,
      .marketingForm .no-wrap-section .outer tbody tr th {
        display: table-cell !important;
      }
      .marketingForm .no-wrap-section.columns-equal-class .outer tbody tr td,
      .marketingForm .no-wrap-section.columns-equal-class .outer tbody tr th {
        height: 0px !important;
      }
      .marketingForm .no-wrap-section .outer tbody tr td.inner,
      .marketingForm .no-wrap-section .outer tbody tr th.inner {
        width: 100% !important;
        height: auto !important;
      }
      .marketingForm a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }
    }
    .notification-container {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .notification-message {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 38px;
      border: 1.44737px solid color('grey');
      border-radius: 10px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .notification-message-hide {
      display: none;
    }

    // overwrite of the dynamics realtime marketing form
    form.marketingForm {
      max-width: 411px !important;
      margin: 3em auto;

      table {
        max-width: 100% !important;
      }

      .textFormFieldBlock label {
        display: none;
      }

      .textFormFieldBlock input,
      .twoOptionFormFieldBlock div.radiobuttons div label,
      .twoOptionFormFieldBlock div.twooption_checkbox div label,
      .optionSetFormFieldBlock div.radiobuttons div label,
      .multiOptionSetFormFieldBlock fieldset > div > label,
      .consentBlock div label {
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: $font-family-base;
      }

      .textFormFieldBlock,
      .dateTimeFormFieldBlock,
      .twoOptionFormFieldBlock,
      .optionSetFormFieldBlock,
      .multiOptionSetFormFieldBlock,
      .lookupFormFieldBlock {
        padding: 0;
      }

      .textFormFieldBlock input,
      .dateTimeFormFieldBlock input,
      .lookupFormFieldBlock input,
      .marketingForm textarea,
      div[data-editorblocktype="Captcha"] input {
        padding: 10px;
        height: auto;
        border: none;
        border-bottom: 1px solid color("black");
        background-color: color("white");
        font-family: $font-family-base;
      }

      .twoOptionFormFieldBlock {
        margin-top: 1rem;
      }

      .twoOptionFormFieldBlock + .twoOptionFormFieldBlock {
        margin-top: 0;
      }

      [data-editorblocktype="SubmitButton"] {
        padding: 0;
      }

      .submitButton {
        width: 100%;
        margin-top: 1.25rem;
        font-family: $font-family-base;

        span {
          font-family: $font-family-base !important;
        }

        &:hover {
          box-shadow: $ti8m-button-shadow;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }

    .section--bg-blue & {
      a {
        color: color("blue-dark");

        &:hover {
          color: color("white");
        }
      }
    }

    .section--bg-light &,
    .section--bg-blue-light &,
    .section--bg-grey & {
      button {
        background-color: color("blue");
      }
      button span {
        color: color("white");
      }
    }

    .section--bg-gradient &,
    .section--bg-green &,
    .section--bg-light &,
    .section--bg-blue-light &,
    .section--bg-grey & {
      color: color("black") !important;
    }

    .section--bg-dark &,
    .section--bg-blue-dark ,
    .section--bg-blue & {
      .textFormFieldBlock label,
      .dateTimeFormFieldBlock label,
      .lookupFormFieldBlock label,
      .twoOptionFormFieldBlock label.block-label,
      .optionSetFormFieldBlock label.block-label,
      .multiOptionSetFormFieldBlock label.block-label,
      div[data-editorblocktype="Captcha"]
        label[id^="wlspispHipInstructionContainer"] {
        color: color("white") !important;
      }
    }

    //overwrite the success-message
    .onFormSubmittedFeedback{
      width: auto !important;
      height: auto !important;

      .onFormSubmittedFeedbackInternalContainerStyle,
      .onFormSubmittedFeedbackInternalContainer {
        padding: $form-padding;

        @include screen-down(md) {
          padding: $form-padding-sm;
        }

        .onFormSubmittedFeedbackIcon {
          display: none !important;
        }

        .onFormSubmittedFeedbackMessage {
          font-family: $font-family-base !important;
          font-size: 1rem;
        }

        .onFormSubmittedFeedbackButton {
          display: none;
        }
      }
    }
  }
}

.section--bg-blue {
  .download-form__html div[data-required] div.twooption_checkbox label::after {
    color: color('white');
  }
}
