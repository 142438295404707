$font-size-base: 16px; // equals to 1rem

$font-family-sans-serif: 'Roboto', 'Raleway', 'Helvetica Neue', helvetica, arial, sans-serif;
$font-family-monospace: 'Roboto Mono', sfmono-regular, menlo, monaco, consolas, 'Liberation Mono', 'Courier New', monospace;

$font-size-lg: 1rem;
$font-size-sm: 0.875rem;

$font-family-base: $font-family-sans-serif;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$line-height-lg: 1.5;
$line-height-sm: 1.42;
$line-height-link-sm: 1.7;

$headings-font-size-lg: (4rem, 2rem, 1.5rem, 1.25rem, 1rem, 1rem);
$headings-line-height-lg: (1.125, 1.25, 1.33, 1.4, 1.5, 1.5);

$headings-font-size-sm: (2.85rem, 2rem, 1.5rem, 1.25rem, 1rem, 1rem);
$headings-line-height-sm: (1.35, 1.35, 1.27, 1.55, 1.42, 1.42);

$header-tag: 1.125rem; // 18px
$header-title: 3.125rem; // 50px
$header-description: 1.25rem; // 20px

// =================================================================

@font-face {
  font-family: 'Roboto';
  src:
    url('../fonts/Roboto/woff/Roboto-Light.woff') format('woff'),
    url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('../fonts/Roboto/woff/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('../fonts/Roboto/woff/Roboto-Italic.woff') format('woff'),
    url('../fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('../fonts/Roboto/woff/Roboto-Medium.woff') format('woff'),
    url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: $font-weight-medium;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('../fonts/Roboto/woff/Roboto-MediumItalic.woff') format('woff'),
    url('../fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: $font-weight-medium;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Mono';
  src:
    url('../fonts/Roboto_Mono/woff/RobotoMono-Regular.woff') format('woff'),
    url('../fonts/Roboto_Mono/RobotoMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Mono';
  src:
    url('../fonts/Roboto_Mono/woff/RobotoMono-Italic.woff') format('woff'),
    url('../fonts/Roboto_Mono/RobotoMono-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Mono';
  src:
    url('../fonts/Roboto_Mono/woff/RobotoMono-Medium.woff') format('woff'),
    url('../fonts/Roboto_Mono/RobotoMono-Medium.ttf') format('truetype');
  font-weight: $font-weight-medium;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Mono';
  src:
    url('../fonts/Roboto_Mono/woff/RobotoMono-MediumItalic.woff') format('woff'),
    url('../fonts/Roboto_Mono/RobotoMono-MediumItalic.ttf') format('truetype');
  font-weight: $font-weight-medium;
  font-style: italic;
  font-display: swap;
}
