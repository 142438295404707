.ti8m-anchor {
  display: block;
  margin: 0.33em auto;
  color: color('blue');
  text-align: center;

  > a {
    font-size: 1.5rem;
    text-decoration: underline;
  }
}

/* this fixes wrong scroll height due to the fixed menu-bar */
:target::before {
  content: '';
  display: block;
  height: $menu-bar-height;
  margin-top: -#{$menu-bar-height};

  @include screen-down(md) {
    height: $menu-bar-height-mobile;
    margin-top: -#{$menu-bar-height-mobile};
  }
}
