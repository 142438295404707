.page-teaser-container {
  width: 100%;
  min-height: 15px;

  &--alternate-bg {

    > :nth-child(even) {
      background-color: color('grey');
    }
  }

  @include screen-up(md) {

    > :nth-child(even) {

      .page-teaser__content {
        flex-direction: row-reverse;
      }

      .page-teaser__image {
        left: -7rem;
      }
    }
  }

  @include screen-down(md) {

    .page-teaser__content {
      flex-direction: column-reverse;
    }
  }
}

.page-teaser {

  &__container {
    display: flex;
    padding: 5rem 0;
    overflow: hidden;
    max-width: $content-area-extended-width;
    width: 100%;
    margin: 0 auto;

    > * {
      flex: 1 1 100%;
    }

    @include screen-down(md) {
      padding: 0;
      background-color: white !important;
      flex-direction: column-reverse;
    }
  }

  @include screen-up(md) {

    &:nth-child(even) {

      .page-teaser__container {
        flex-direction: row-reverse;
      }

      .page-teaser__text {
        padding-right: 10rem;
        padding-left: 7.5rem;
      }
    }

    &:nth-child(odd) {

      .page-teaser__text {
        padding-left: 10rem;
        padding-right: 7.5rem;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.125rem;
    font-weight: 400;

    @include screen-down(md) {
      padding: 3rem 1.5rem;
    }

    h2 {
      font-weight: $font-weight-medium;
      font-size: 2rem;
    }

    p {
      padding-bottom: 1rem;
    }

    ul,
    ol {
      list-style: initial; // fallback
      list-style: revert;
      padding-left: 2rem;
    }
  }

  &__image {
    overflow: hidden;
    display: grid;
    place-items: center center;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  @include screen-down(md) {

    &__image {
      width: 100%;
      max-height: 375px;
    }
  }
}
