.video {

  &__title {
    text-align: center;
    margin-bottom: 3rem;
  }

  iframe {
    max-width: 100%;
  }

  &__cookie-alert {
    display: none;
  }

  .js-yt-player.has-rejected-cookies {
    display: none;

    & + .video__cookie-alert {
      display: flex;
      justify-content: center;
      align-items: center;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      max-width: 100%;
      box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.3);
    }
  }

  &--full-screen {
    height: 90vh;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
