// scrolling content teaser

.scrolling-content-teaser {

  &__title {
    font-size: 3rem;
    font-weight: $font-weight-medium;
    text-align: center;
    max-width: $content-area-width;
    margin: 0 auto;
    padding: 5rem 0;

    @include screen-down(md) {
      padding: 5rem 0;
    }
  }

  &__container {
    display: flex;
    max-width: 930px;
    margin: 0 auto;
  }

  &__mobile-image {
    display: block;
    margin: 1rem auto;
    max-width: 90%;
  }

  &__left,
  &__right {
    position: relative;
  }

  &__left {
    width: 45%;

    @include screen-down(md) {
      width: 100%;
    }
  }

  &__right {
    width: 55%;
  }

  &__element {
    padding: 7.5rem 0 13.75rem;

    @include screen-down(md) {
      margin: 3rem 1.5rem;
      padding: 0;
    }
  }

  &__icon {
    width: 36px;
  }

  &__highlight {
    margin-top: 15px;
    margin-bottom: 10px;
    font-family: $font-family-sans-serif;
    font-size: 1rem;
    font-weight: $font-weight-medium;
  }

  &__element-title {
    margin-top: 1.125rem;
    margin-bottom: 1rem;
    font-size: 2.25rem;
  }

  &__description {
    font-size: 1rem;
    margin-bottom: 0.625rem;
    font-weight: $font-weight-base;
  }

  &__image {
    position: sticky;
    width: 400px;
    height: 400px;
    top: calc(50% - 200px);
    left: 50%;
    display: block;
    margin: auto;
    margin-bottom: 100px;
    background-size: cover;
    overflow: hidden;
  }

  &__image-content {
    position: absolute;
    width: 100%;
    transition: opacity 250ms linear;
  }
}
