.slider-container {
  padding: 5rem 0;
  margin: 0 auto;
  width: 100%;
  max-width: $content-area-extended-width;
}

.slider-card {
  display: flex;
  background-color: color('blue-light');
  color: color('black');
  border-radius: 8px;
  overflow: hidden;
  margin: 1.5rem;
  box-shadow: $ti8m-card-shadow;

  @include screen-down(lg) {
    margin: 0.5rem;
    flex-direction: column-reverse;
  }

  &__content {
    flex-grow: 1;
    align-self: flex-start;
    padding: $margin-4;
    width: 66%;

    @include screen-down(lg) {
      padding: $margin-3;
      width: 100%;
    }
  }

  &__image-container {
    padding: 0;
    height: 100%;
    width: 33%;
    overflow: hidden;

    @include screen-down(lg) {
      width: 100%;
      height: auto;
      max-height: 200px;
    }

    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.image-slider {

  &__slide {
    margin: 0 $margin-1;
  }

  &__image {
    max-height: 500px;
    max-width: 100%;
    margin: auto;
  }
}

// ===============================================================
// styles overrides for the Slick slider

.slick-track {
  display: flex;
  padding-bottom: $margin-4;
}

.slick-initialized .slick-slide {
  display: flex;
  align-items: center;
}

.slick-dots li {
  width: 48px;
  height: 4px;

  button {

    &::before {
      width: 48px;
      height: 4px;
      background-color: color('blue');
      content: '';
      opacity: 0.4;
    }
  }

  &.slick-active { /* stylelint-disable-line */

    button {

      &::before {
        opacity: 1;
      }
    }
  }
}

.slick-next {
  right: 1.5rem;
  z-index: 1;
  top: calc(50% - 2rem);
  width: 3rem;
  height: 3rem;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  @include screen-down(sm) {
    right: 0;
  }

  svg {
    color: color('white');
    height: 28px;
    width: auto;
    stroke: color('blue');
    stroke-width: 1px;
  }

  &::before {
    content: '';
  }
}

.slick-prev {
  left: 1rem;
  z-index: 1;
  top: calc(50% - 2rem);
  width: 3rem;
  height: 3rem;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

  @include screen-down(sm) {
    left: 0;
  }

  svg {
    color: color('white');
    height: 28px;
    width: auto;
    stroke: color('blue');
    stroke-width: 1px;
  }

  &::before {
    content: '';
  }
}
