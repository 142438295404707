@mixin button-on-light {

  .button {
    background-color: color('blue');

    .button__text {
      color: color('white');
    }

    &--text {
      background-color: transparent;
    }

    &--text .button__text {
      color: color('blue');
    }
  }
}

@mixin button-on-blue {

  .button {
    background-color: color('blue-dark');

    .button__text {
      color: color('white');
    }

    &--text {
      background-color: transparent;

      .button__text {
        color: color('white');
      }
    }
  }
}

@mixin button-on-green {

  .button {
    background-color: color('white');

    .button__text {
      color: color('black');
    }

    &--text {
      background-color: transparent;

      .button__text {
        color: color('black');
      }
    }
  }
}

.section {
  position: relative;

  &__container {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: $content-area-extended-width;
    padding: 0;
  }

  &--bg-light,
  &--bg-white {
    background-color: color('white');
    color: color('black');

    @include button-on-light;
  }

  &--bg-blue-light {
    background-color: color('blue-light');
    color: color('black');

    @include button-on-light;
  }

  &--bg-grey {
    background-color: color('grey');
    color: color('black');

    @include button-on-light;
  }

  &--bg-blue {
    background-color: color('blue');
    color: color('white');
    font-weight: $font-weight-medium;

    @include button-on-blue;

    .c-text__wrapper .c-text__content {

      a {
        color: color('blue-dark');

        &:hover {
          color: color('blue-dark');
        }
      }
    }
  }

  &--bg-green {
    background-color: color('green');
    color: color('black');
    font-weight: $font-weight-medium;

    @include button-on-green;
  }

  &--bg-dark,
  &--bg-blue-dark {
    background-color: color('blue-dark');
    color: color('white');

    .button {
      background-color: color('white');
      color: color('black');

      &--text {
        background-color: transparent;
        color: color('white');
      }

      &--outline {
        background-color: transparent;
        color: color('white');
        border-color: color('white');

        &:hover {
          background-color: color('white');
          color: color('black');
        }
      }
    }
  }

  &--bg-gradient {
    background-color: color('green');
    color: color('black');

    @include screen-down(md) {
      background: $ti8m-gradient-mobile;
    }

    .button {
      background-color: color('white');
      color: color('black');

      &--text {
        background-color: transparent;
        color: color('white');
      }

      &__text::after {
        background: color('white');
      }
    }
  }
}
