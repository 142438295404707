.story-overview {
  color: color('black');
  padding-top: 5rem;
  padding-bottom: 5rem;

  @include screen-down(md) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &__info {
    margin-bottom: 1rem;
  }

  &__info-title {
    color: color('blue');
    font-family: $font-family-monospace;
  }

  &__list {
    margin: 0;
  }

  &__list-item {
    margin-bottom: 0.25rem;
  }

  &__story-content {

    strong {
      font-weight: $font-weight-medium;
    }
  }

  &__list-link {
    display: inline-block;
    color: unset;
    font-weight: $font-weight-base;
    border-bottom: solid 2px color('blue-light');
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &[href^='http'] {

      &::after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2168 8.74089V12.7409C12.2168 13.0945 12.0763 13.4336 11.8263 13.6837C11.5762 13.9337 11.2371 14.0742 10.8835 14.0742H3.55013C3.19651 14.0742 2.85737 13.9337 2.60732 13.6837C2.35727 13.4336 2.2168 13.0945 2.2168 12.7409V5.40755C2.2168 5.05393 2.35727 4.71479 2.60732 4.46474C2.85737 4.21469 3.19651 4.07422 3.55013 4.07422H7.55013' stroke='black' stroke-opacity='0.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.2171 2.07422H14.2171M14.2171 2.07422V6.07422M14.2171 2.07422L6.88379 9.40755' stroke='black' stroke-opacity='0.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        width: 1rem;
        height: 1rem;
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
}
