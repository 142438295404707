.c-text {
  padding: 5rem 0;
  background-size: cover;

  @include screen-down(md) {
    padding: 2.5rem 1rem;
  }

  &__wrapper {
    display: block;
    margin: 0 auto;
    padding: 0 1rem;
    max-width: $content-area-width;
  }

  &__title {
    font-weight: $font-weight-medium;
    font-size: 2rem;
  }

  &__content {
    font-size: 1rem;
    text-overflow: clip;
    line-height: 1.5em;
    margin-bottom: 1rem;

    ul,
    ol {
      list-style: initial; // fallback
      list-style: revert;
      padding-left: 2rem;
    }

    p,
    li {
      opacity: 0.7;
    }

    blockquote {
      margin: 1rem auto;
      padding: 2rem 1rem;
      border-top: 1px solid color('blue');
      border-bottom: 1px solid color('blue');

      p {
        font-family: Georgia, sans-serif;
        font-size: 1.5rem;
        font-weight: $font-weight-normal;
        font-style: italic;
        color: color('blue');
        margin: 0;
      }

      p:first-child {

        &::before {
          content: open-quote;
        }

        &::after {
          content: close-quote;
        }
      }
    }
  }
}
