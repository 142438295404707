.footer {
  position: relative;
  padding-top: 8px;
  background-color: color('blue-dark');
  overflow: hidden;
  color: color('white');

  &-button {
    background-color: rgba(color('white'), 0.1);
    padding: 5px;
    border-radius: 3px;
  }

  &__container {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-top: 3rem;

    &--upper {
      margin-bottom: 2rem;
    }

    /* stylelint-disable */
    // override of dynamics styles of the form submission in the footer__container
    .newsletter-form__d365 .formLoader {
      border-top: 16px solid color('blue');
    }
    div[data-form-block-id] .onFormSubmittedFeedback,
    div[data-form-id] .onFormSubmittedFeedback {
      height: 60px !important;
      margin-top: 0;
      background: rgba(color('white'), 0.1);
      border-radius: 5px;

      .onFormSubmittedFeedbackInternalContainerStyle,
      .onFormSubmittedFeedbackInternalContainer {
        flex-direction: row;

        .onFormSubmittedFeedbackIcon {
          display: none;
        }

        .onFormSubmittedFeedbackMessage {
          display: flex;
          padding: 8px;
          color: rgba(color('white'), 0.5);
          font-family: $font-family-base;
          font-size: 1rem !important;
        }

        .onFormSubmittedFeedbackButton {
          display: none;
        }
      }
    }

    div[data-form-block-id] .dialogProviderContainerStyle,
    div[data-form-id] .dialogProviderContainerStyle {
      width: 100% !important;
      height: auto !important;
      position: relative;

      .dialogProviderInternalContainerStyle {
        position: relative;
      }
    }
    /* stylelint-enable */
  }

  &__socials {
    margin-top: 3rem;
    display: flex;
    justify-content: start;

    @include screen-up(lg) {
      margin-top: 0;
    }

    @include screen-down(sm) {
      justify-content: center;
    }
  }

  &__icon {
    background: color('blue');
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__branch {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 1.25rem;
      font-weight: $font-weight-medium;
      line-height: 1.75rem;
      word-break: break-word;
      margin-bottom: 0.5rem;
      min-height: 2em;
    }

    a,
    address {
      line-height: 1.5em;
      font-weight: $font-weight-base;
      font-size: 1rem;
      opacity: 0.7;
      margin-bottom: 0;
      color: rgba(color('white'), 1);
    }
  }

  &__end {
    margin-top: 3rem;
    border-top: solid 1px color('white');
    min-height: 55px;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &__links {

    > * {
      margin-right: 1rem;
    }

    > *:last-child {
      margin-right: 0;
    }

    @include screen-down(md) {
      display: flex;
      flex-direction: column;

      > * {
        margin-bottom: 0.5rem;
      }
    }
  }

  &__copyright {
    margin-right: 0;
    text-align: right;
    min-width: fit-content;

    @include screen-up(lg) {
      margin-right: 3rem;
    }

    @include screen-up(xl) {
      margin-right: 0;
    }
  }

  // Override Dynamics form styles of Newsletter form
  /* stylelint-disable */
  &.section--bg-blue-dark .lpt-webinarStatic .flex--wrapper {
    border-radius: 5px;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.section--bg-blue-dark .lpt-webinarStatic input[type="email"] {
    border-bottom: none;
    background-color: rgba(255, 255, 255, 0);
    color: color('white');
  }

  &.section--bg-blue-dark .lpt-webinarStatic input[type="email"]:-internal-autofill-selected {
    background-color: rgba(255, 255, 255, 0) !important;
    background-image: none !important;
    color: color('white') !important;
  }

  &.section--bg-blue-dark .lpt-webinarStatic input[type="email"]::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }

  &.section--bg-blue-dark .lpt-webinarStatic button {
    background-color: color('blue');
    color: color('white');
    white-space: nowrap;
  }

  &.section--bg-blue-dark .lp-form-field .lp-ellipsis a {
    color: color('white');
  }

  &.section--bg-blue-dark .lp-form-field .lp-ellipsis a:hover {
    color: color('blue-light');
  }
  .twooption_checkbox a:hover {
    color: color('white');
    text-decoration: none;
  }
  /* stylelint-enable */
}
