.logo-container {
  padding-top: 5rem;
  padding-bottom: 5rem;

  .container {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include screen-down(md) {
      padding-top: unset;
      padding-bottom: unset;
    }
  }

  .slick-track {
    padding-bottom: 0;
  }

  &__title {
    text-align: center;
    font-weight: $font-weight-medium;
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }

  &__logo {
    height: 100px;
    vertical-align: baseline;
  }

  .nav-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    a {
      text-decoration: none;
    }
  }

  &__pill {
    font-weight: $font-weight-medium;
    font-size: 1rem;
    color: color('black');
    margin-right: 1.5rem;
    padding-bottom: 2px;

    a {
      text-decoration: none;
    }
  }

  &__pill:hover {
    text-decoration: none;
  }

  &__pill.active {
    border-bottom: 3px solid color('blue');
  }

  .tab-content > .tab-pane {
    display: none;
  }

  .tab-content > .active {
    display: block;
  }

  .tab-content {

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      row-gap: 3rem;
      column-gap: 1.5rem;
      padding-left: 8px;
      padding-right: 8px;
    }

    li {
      display: inline-flex;
      justify-content: center;
      width: calc(100% / 7);

      @include screen-down(md) {
        width: calc(100% / 5);
      }

      @include screen-down(xs) {
        width: calc(100% / 3);
      }
    }
  }
}
