.sub-nav {
  display: flex;

  &__info {
    width: 33%;
    padding: 3rem 2.5rem;

    @include screen-down(md) {
      display: none;
    }

    &-title {
      display: block;
      font-size: 2rem;
      margin: 0.5em 0;
      color: color('blue');

      &:hover {
        color: color('blue');
      }

      a {
        font-size: inherit;
        color: inherit;
        text-decoration: none;

        &:hover {
          color: inherit;
          text-decoration: underline solid 2px color('blue-light');
        }
      }
    }

    &-description {
      color: rgba(color('black'), 1);
      margin-bottom: 1em;
    }
  }

  &__lists-container {
    width: 67%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 3rem 1rem 1rem 0;
    overflow-y: auto;

    @include screen-down(md) {
      padding-left: 1rem;
    }

    > * {
      width: 50%;
    }
  }

  &__list {
    font-size: 1rem;
    border-left: solid 1px color('blue');
    padding-left: 1em;

    a {
      font-weight: normal;
      color: color('black');
      text-decoration: none;

      &:hover {
        text-decoration: underline solid 2px color('blue-light');
      }
    }

    > * {
      margin-bottom: 1.5em;

      &:first-child * {
        font-family: $font-family-monospace;
        color: color('blue');
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
