#start-page {

  .page-header {
    position: relative;
    display: block;
    min-height: 100vh;
    background: color('blue');
    color: color('white');
    overflow-y: hidden;
    background-size: 100% 100%;

    &--light,
    &--blue-light,
    &--grey,
    &--green {
      color: color('black');

      .page-header__button {
        color: color('black');
      }
    }

    &--light {
      background-color: color('white');
    }

    &--blue-light {
      background-color: color('blue-light');
    }

    &--grey {
      background-color: color('grey');
    }

    &--blue {
      background-color: color('blue');
      color: color('white');
    }

    &--green {
      background-color: color('green');
    }

    &__image-container {
      margin: 0 auto;
      width: 100%;
      background-size: cover;
      background-position-x: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      max-width: $content-area-extended-width;
      max-height: 50%;
      padding: 0 1.5rem;

      @include screen-down(md) {
        height: 50%;
        padding: 0 0.75rem;
      }

      /* hide the hero image for really thin screens screens on landscape mode (eg. Galaxy Fold) */
      @media screen and (max-height: 300px) {
        display: none;
      }
    }

    &__content {
      position: relative;
      text-align: center;
      z-index: 3;
    }

    &__title,
    &__subtitle {
      max-width: 50vw;
      font-size: 7vh;
      margin: 1rem auto 0.5rem;
      hyphens: none;

      @include screen-down(md) {
        max-width: 90%;
      }

      @include screen-down(xs) {
        font-size: 2rem;
      }
    }

    &__subtitle {
      font-size: nth($headings-font-size-lg, 2);
      margin-bottom: 1rem;

      @include screen-down(xs) {
        font-size: nth($headings-font-size-lg, 3);
      }
    }

    &__button {
      color: color('white');
      display: inline-block;
    }
  }

  .page-content {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
}
