html,
body {
  height: 100%;
  font-size: $font-size-base;
  font-family: $font-family-base;
}

body {
  word-break: normal;
  hyphens: auto;
}

h1,
h2,
h3 {
  margin: 1rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-sans-serif;
}

@for $i from 1 through 6 {
  h#{$i},
  .h#{$i} {
    font-size: nth($headings-font-size-lg, $i);
    line-height: nth($headings-line-height-lg, $i);

    @include screen-down(sm) {
      font-size: nth($headings-font-size-sm, $i);
      line-height: nth($headings-line-height-sm, $i);
    }
  }
}

h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  font: inherit !important;
  line-height: inherit !important;
  color: inherit !important;
  text-decoration: inherit !important;

  &:hover,
  &:active {
    color: inherit !important;
    text-decoration: inherit !important;
  }
}

p {
  font-size: 1rem;
  font-weight: $font-weight-normal;
  line-height: $line-height-lg;
  opacity: 0.7;
  margin: 0;
}

p:not(:empty) {
  margin-bottom: 1em;
}

a {
  font-size: $font-size-lg;
  font-weight: $font-weight-normal;
  line-height: $line-height-lg;
  text-decoration: none;
  color: color('blue');

  &:hover {
    color: inherit;
  }
}

@include screen-down(sm) {

  p {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    line-height: $line-height-sm;
  }

  a {
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    line-height: $line-height-link-sm;
  }
}

.screenreader-skiplink {
  position: absolute;
  background-color: white;
  color: blue;
  top: 0;
  left: -1000px;
  display: inline-block;
  padding: 0.25rem;
  z-index: 1000;

  &:focus {
    left: 0;
  }
}
