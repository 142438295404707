.cards-container {
  gap: 1.5rem;
  display: grid;
  padding: 10px;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @for $i from 1 through 4 {
    &--#{$i}-cols {
      grid-template-columns: repeat(#{$i}, 1fr);
    }
  }

  @include screen-down(md) {
    grid-template-columns: 1fr;
  }

  &--edit-mode {
    display: block;
    max-width: 50%;

    .card {
      height: auto;
      margin-bottom: 12px;
    }
  }
}

.dynamics-content {

  .card {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.card {
  height: 100%;
  background-color: color('white');
  box-shadow: $ti8m-card-shadow;
  border: none;
  border-radius: 8px;
  padding: 0;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  overflow: hidden;

  a {
    text-decoration: none;
  }

  @include screen-up('md') {

    &--col-1 {
      grid-area: span 1 / span 1;
    }

    &--col-2 {
      grid-area: span 1 / span 2;
    }

    &--col-3 {
      flex-direction: row;
      grid-area: span 1 / span 3;

      .card__image {
        height: 100%;
        width: 50%;
      }
    }
  }

  // OLD Dark blue
  &--blue-dark {
    background-color: color('blue-dark');
    color: color('white');

    .card__content {
      color: color('white');
    }

    .card__tag {
      color: color('green');
    }

    .button.card__link.button--text .button__text {
      color: color('white');
    }

    // White arrow icon
    .quick-links li {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAnIGhlaWdodD0nMjAnIHZpZXdCb3g9JzAgMCAyMCAyMCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAuMDAwMyAxOC45NTc3QzUuMDU4NjYgMTguOTU3NyAxLjA0MTk5IDE0Ljk0MSAxLjA0MTk5IDkuOTk5MzVDMS4wNDE5OSA1LjA1NzY4IDUuMDU4NjYgMS4wNDEwMiAxMC4wMDAzIDEuMDQxMDJDMTQuOTQyIDEuMDQxMDIgMTguOTU4NyA1LjA1NzY4IDE4Ljk1ODcgOS45OTkzNUMxOC45NTg3IDE0Ljk0MSAxNC45NDIgMTguOTU3NyAxMC4wMDAzIDE4Ljk1NzdaTTEwLjAwMDMgMi4yOTEwMkM1Ljc1MDMzIDIuMjkxMDIgMi4yOTE5OSA1Ljc0OTM1IDIuMjkxOTkgOS45OTkzNUMyLjI5MTk5IDE0LjI0OTMgNS43NTAzMyAxNy43MDc3IDEwLjAwMDMgMTcuNzA3N0MxNC4yNTAzIDE3LjcwNzcgMTcuNzA4NyAxNC4yNDkzIDE3LjcwODcgOS45OTkzNUMxNy43MDg3IDUuNzQ5MzUgMTQuMjUwMyAyLjI5MTAyIDEwLjAwMDMgMi4yOTEwMlonIGZpbGw9J3doaXRlJy8+PHBhdGggZD0nTTEyLjA4MzcgMTAuNjI0M0g3LjA4MzY2QzYuNzQxOTkgMTAuNjI0MyA2LjQ1ODY2IDEwLjM0MSA2LjQ1ODY2IDkuOTk5MzVDNi40NTg2NiA5LjY1NzY4IDYuNzQxOTkgOS4zNzQzNSA3LjA4MzY2IDkuMzc0MzVIMTIuMDgzN0MxMi40MjUzIDkuMzc0MzUgMTIuNzA4NyA5LjY1NzY4IDEyLjcwODcgOS45OTkzNUMxMi43MDg3IDEwLjM0MSAxMi40MjUzIDEwLjYyNDMgMTIuMDgzNyAxMC42MjQzWicgZmlsbD0nd2hpdGUnLz48cGF0aCBkPSdNMTAuNDE2NiAxMy4xMjQzQzEwLjI1ODIgMTMuMTI0MyAxMC4wOTk5IDEzLjA2NTkgOS45NzQ5MSAxMi45NDA5QzkuNzMzMjQgMTIuNjk5MyA5LjczMzI0IDEyLjI5OTMgOS45NzQ5MSAxMi4wNTc2TDEyLjAzMzIgOS45OTkyN0w5Ljk3NDkxIDcuOTQwOTdDOS43MzMyNCA3LjY5OTMgOS43MzMyNCA3LjI5OTMgOS45NzQ5MSA3LjA1NzYzQzEwLjIxNjYgNi44MTU5NyAxMC42MTY2IDYuODE1OTcgMTAuODU4MiA3LjA1NzYzTDEzLjM1ODIgOS41NTc2QzEzLjU5OTkgOS43OTkyNyAxMy41OTk5IDEwLjE5OTMgMTMuMzU4MiAxMC40NDA5TDEwLjg1ODIgMTIuOTQwOUMxMC43MzMyIDEzLjA2NTkgMTAuNTc0OSAxMy4xMjQzIDEwLjQxNjYgMTMuMTI0M1onIGZpbGw9J3doaXRlJy8+PC9zdmc+');
    }
  }

  &--white, // OLD white
  &--blue-light,
  &--light {
    color: color('black');

    .card__content {
      color: color('black');
    }

    .card__tag {
      color: color('blue');
    }

    .card__date {
      color: color('black');
    }

    .card__link.button--text .button__text {
      color: color('blue');
    }

    // Black arrow icon
    .quick-links li {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAnIGhlaWdodD0nMjAnIHZpZXdCb3g9JzAgMCAyMCAyMCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAuMDAwMyAxOC45NTc3QzUuMDU4NjYgMTguOTU3NyAxLjA0MTk5IDE0Ljk0MSAxLjA0MTk5IDkuOTk5MzVDMS4wNDE5OSA1LjA1NzY4IDUuMDU4NjYgMS4wNDEwMiAxMC4wMDAzIDEuMDQxMDJDMTQuOTQyIDEuMDQxMDIgMTguOTU4NyA1LjA1NzY4IDE4Ljk1ODcgOS45OTkzNUMxOC45NTg3IDE0Ljk0MSAxNC45NDIgMTguOTU3NyAxMC4wMDAzIDE4Ljk1NzdaTTEwLjAwMDMgMi4yOTEwMkM1Ljc1MDMzIDIuMjkxMDIgMi4yOTE5OSA1Ljc0OTM1IDIuMjkxOTkgOS45OTkzNUMyLjI5MTk5IDE0LjI0OTMgNS43NTAzMyAxNy43MDc3IDEwLjAwMDMgMTcuNzA3N0MxNC4yNTAzIDE3LjcwNzcgMTcuNzA4NyAxNC4yNDkzIDE3LjcwODcgOS45OTkzNUMxNy43MDg3IDUuNzQ5MzUgMTQuMjUwMyAyLjI5MTAyIDEwLjAwMDMgMi4yOTEwMlonIGZpbGw9J2JsYWNrJy8+PHBhdGggZD0nTTEyLjA4MzcgMTAuNjI0M0g3LjA4MzY2QzYuNzQxOTkgMTAuNjI0MyA2LjQ1ODY2IDEwLjM0MSA2LjQ1ODY2IDkuOTk5MzVDNi40NTg2NiA5LjY1NzY4IDYuNzQxOTkgOS4zNzQzNSA3LjA4MzY2IDkuMzc0MzVIMTIuMDgzN0MxMi40MjUzIDkuMzc0MzUgMTIuNzA4NyA5LjY1NzY4IDEyLjcwODcgOS45OTkzNUMxMi43MDg3IDEwLjM0MSAxMi40MjUzIDEwLjYyNDMgMTIuMDgzNyAxMC42MjQzWicgZmlsbD0nYmxhY2snLz48cGF0aCBkPSdNMTAuNDE2NiAxMy4xMjQzQzEwLjI1ODIgMTMuMTI0MyAxMC4wOTk5IDEzLjA2NTkgOS45NzQ5MSAxMi45NDA5QzkuNzMzMjQgMTIuNjk5MyA5LjczMzI0IDEyLjI5OTMgOS45NzQ5MSAxMi4wNTc2TDEyLjAzMzIgOS45OTkyN0w5Ljk3NDkxIDcuOTQwOTdDOS43MzMyNCA3LjY5OTMgOS43MzMyNCA3LjI5OTMgOS45NzQ5MSA3LjA1NzYzQzEwLjIxNjYgNi44MTU5NyAxMC42MTY2IDYuODE1OTcgMTAuODU4MiA3LjA1NzYzTDEzLjM1ODIgOS41NTc2QzEzLjU5OTkgOS43OTkyNyAxMy41OTk5IDEwLjE5OTMgMTMuMzU4MiAxMC40NDA5TDEwLjg1ODIgMTIuOTQwOUMxMC43MzMyIDEzLjA2NTkgMTAuNTc0OSAxMy4xMjQzIDEwLjQxNjYgMTMuMTI0M1onIGZpbGw9J2JsYWNrJy8+PC9zdmc+Cg==');
    }
  }

  &--white, // OLD white
  &--light {
    background-color: color('white');
  }

  &--blue-light {
    background-color: color('blue-light');
  }

  &__image {
    width: 100%;
    height: 200px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    background-size: cover;
    background-position-x: center;
    flex-shrink: 0;

    @include screen-down('md') {
      height: 300px;
    }

    @include screen-down('sm') {
      height: 200px;
    }

    img {
      min-height: 100%;
      max-width: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__body {
    overflow: hidden;
    padding: 2.5rem 2rem;
    border-radius: 8px;
    background-color: transparent;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__title {
    padding: 0;
    margin: 0;
    font-weight: $font-weight-medium;
    margin-bottom: 0.25rem;
  }

  &__tag {
    font-size: $font-size-sm;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-medium;
    padding-right: 1em;
    min-height: 21px;
  }

  &__date {
    font-size: $font-size-sm;
    font-weight: 400;
    font-family: $font-family-sans-serif;
    margin-bottom: 0.25rem;
    white-space: pre-wrap;
  }

  &__text {
    padding: 0;
    margin: 0;
    text-overflow: clip;
    flex-grow: 1;
    font-size: $font-size-lg;
    font-weight: $font-weight-normal;
    line-height: $line-height-lg;
    opacity: 0.7;
  }

  &__extra {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    margin-top: $margin-3;
    margin-bottom: 0 !important;
  }

  &__link {
    margin-top: $margin-3;
  }

  &--fixed-image-height {

    .card__image {
      display: flex;
    }

    .card__image img {
      height: 100%;
      width: auto;
      object-fit: contain;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include screen-up(sm) {

    &--horizontal {
      height: 300px;
      flex-direction: row;

      .card__image {
        width: 33%;
        border-radius: 0;
      }

      > * {
        height: inherit;
      }
    }
  }

  &__content:hover {
    text-decoration: none;
  }

  .quick-links {
    margin-top: 16px;

    li {
      list-style-type: none;
      margin: 0;
      background-position: left 4px;
      background-repeat: no-repeat;
      padding: 0 0 0 28px;

      a {
        font-weight: 500;
        line-height: 28px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
