.ti8m-code {
  display: block;
  font-family: monospace;
  border: dashed 1px gray;
  background-color: aliceblue;
  color: gray;
  padding: 12px;
  white-space: pre;
  overflow: auto;
  max-height: 300px;
}
