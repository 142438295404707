.blog-search {
  display: flex;
  width: 100%;
  max-width: 60ch;
  border-radius: 2px;
  border: solid 1px color('blue');
  color: color('blue');
  padding: 4px 10px;
  font-size: 0.875rem;
  font-weight: 400;

  @include screen-down(md) {
    max-width: 100%;
  }

  &:hover,
  &:active {
    background: transparent;
    color: color('blue');
  }

  &__input-field {
    background: none;
    padding: 0 !important;
    border: none !important;
    flex-grow: 1;

    &::placeholder {
      color: color('blue');
    }
  }

  &__submit {
    color: inherit;
    border: none;
    background: none;
    padding: 0;
  }
}
