.page-highlight {
  display: flex;
  flex-direction: column;

  &__header {
    min-height: 33%;
    background-color: color('black');
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }

  &__content {
    flex-grow: 1;
    background-color: color('blue-light');
    padding: 2rem 2rem 2rem 3rem;
    color: rgba(color('black'), 1);
  }

  &__title {
    font-size: 1.5rem;
    line-height: 1.33em;
    font-weight: $font-weight-medium;
    color: color('blue');
    margin: 0.33em 0;

    &:hover {
      color: color('blue');
    }
  }

  &__description {
    margin-bottom: 1.5em;
  }

  &__link {
    color: color('black');
    border-bottom: solid 0.25rem color('blue');
    padding-bottom: 0.25rem;

    &:hover {
      color: color('black');
      text-decoration: none;
    }
  }
}
