.menu-list__link.is-active + .menu-list__item-icon {
  transform: rotate(90deg);
}

.main-menu__dropdown.has-selection .menu-list__item  {
  &.is-active {
    .menu-list__item-icon {
      opacity: 1;
    }
  }

  &:not(.is-active) {
    .menu-list__item-icon {
      opacity: .5;
    }
  }
}

.menu-list {

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__link {
    display: inline-block;
    padding: 1rem;
    color: color('black');
    font-size: 1.125rem;
    font-weight: $font-weight-medium;

    &--light {
      font-weight: 300;
    }

    &:hover {
      color: color('black');
    }

    &.is-active {

      .button__text::after {
        width: 100%;
      }
    }
  }

  &__item-icon {
    color: color('blue');
    transition:.25s ease-in-out;
  }

  &__button {
    color: color('blue');
    background: none;
    border: none;
    appearance: none;
    display: inline-flex;
    align-items: center;
    text-align: left;
  }
}
