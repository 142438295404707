.reference {
  overflow-y: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 900px;
  display: flex;
  align-items: center;

  &__bg {
    position: absolute;
    pointer-events: none;
    object-fit: cover;
    object-position: center;
    min-height: 100%;
    width: 100%;
  }

  @include screen-down(sm) {
    min-height: 375px;
    flex-direction: column;

    .container {
      max-width: none;
      margin: 0;
      padding: 1.5rem;
    }
  }

  &__image {
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 60%;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;

    @include screen-down(sm) {
      position: static;
      width: 100%;
      justify-content: center;
    }

    img {
      width: 100%;
      object-fit: cover;
      object-position: left;
    }
  }

  &__description {
    z-index: 1;

    @include screen-down(md) {
      padding: 0;
      margin-top: 1.5rem;
    }
  }

  &__tag {
    font-size: $font-size-sm;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-medium;
    opacity: 0.8;
    margin-bottom: $margin-2;
  }

  &__title {
    font-weight: 400;
    margin-bottom: $margin-1;
    hyphens: none;

    @include screen-down(sm) {
      font-size: 1.75rem;
    }
  }

  &__text {
    opacity: 0.8;
    margin-bottom: $margin-4;
    font-size: 1.25rem;

    @include screen-down(sm) {
      font-size: 1.125rem;
    }
  }
}
