.search-result {

  &__form-container {
    margin-top: 8rem;
    margin-bottom: 5rem;

    .search-form {

      .search-button {
        border: none;
        background-color: color('white');
        position: absolute;
        right: 14px;
        bottom: 5px;
        color: color('blue');
      }
    }

    input {
      width: 100%;
      border: none;
      outline: none;
      border-bottom: 3px solid color('blue');
    }
  }

  &__results-container {

    hr {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .search-result:first-child {
    padding-top: 8px;
    border-top: 1px solid color('blue-light');
  }

  .search-result {

    &__title {
      color: color('blue');
    }

    &__excerpt {
      font-size: $font-size-lg;
      opacity: 0.8;
      color: color('black');
      margin-bottom: 24px;

      strong {
        font-weight: normal;
      }
    }
  }
}
