.mobile-menu {
  width: 100%;
  position: relative;

  @include screen-up(md) {
    display: none;
  }

  &__controls {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    padding: 1.5rem;
    color: rgba(color('black'), 1);
  }
}

.mobile-menu-panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }
}

@include screen-down(md) {

  .menu-list {
    background: color('white');
    transition: transform 300ms ease-out;
    transition-delay: 100ms;
    width: 100%;
    padding: 1.5rem;
    position: absolute;

    &--hidden {
      position: fixed;
      transform: translateX(100%);
    }
  }
}

.mobile-menu-back-button {
  display: none;
  color: color('blue');

  svg {
    transform: scaleX(-1);
  }

  @include screen-up(md) {
    display: none !important;
  }
}
