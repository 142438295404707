$breakpoints: (
  'xl': 1400px,
  'lg': 1168px,
  'md': 992px,
  'sm': 768px,
  'xs': 575px
);

/*
=====================================================
USAGE
=====================================================

.block-name {
  // mobile rules ...

  @include screen-up(lg) {
    // desktop only rules ...
  }
}

.block-name {
  // desktop rules ...

  @include screen-down(sm) {
    // mobile only rules ...
  }
}
=====================================================
*/

@mixin screen-up($key) {
  @if map-has-key($breakpoints, $key) == false {
    @error "Unknown breakpoint '#{$key}'";
  }

  $size: map-get($breakpoints, $key);

  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin screen-down($key) {
  @if map-has-key($breakpoints, $key) == false {
    @error "Unknown breakpoint '#{$key}'";
  }

  $size: map-get($breakpoints, $key);

  @media screen and (max-width: $size) {
    @content;
  }
}
