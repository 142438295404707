select {
  border: none;
  font-size: 1rem;
  padding: 0.5em;
  border-bottom: solid 3px color('blue');
  margin-right: 1rem;
}

option {
  background: color('blue-light');
}
