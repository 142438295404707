.map {

  .map__container {
    padding: 5rem 2rem 5rem 0;

    @include screen-down(sm) {
      padding: 0;
      overflow: hidden;
    }

    .map__canvas {
      height: 440px;
      width: 600px;
      max-width: 100%;

      @include screen-down(sm) {
        max-width: 100%;
        margin: auto;
      }
    }
  }

  .map__description {
    padding-left: 5rem;
    margin: auto;

    @include screen-down(sm) {
      padding: $margin-3;
    }

    p {
      margin-bottom: 0;
    }

    .map__button {
      padding-top: $margin-3;
    }
  }
}
