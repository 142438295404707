.quote {
  background-color: white;
  padding-top: 100px;
  padding-bottom: 100px;

  &-wrapper {
    max-width: $content-area-width;
    margin: 0 auto;
    padding: 2rem 1rem;
    border-top: 1px solid color('blue');
    border-bottom: 1px solid color('blue');

    q {
      font-family: Georgia, sans-serif;
      font-size: 1.5rem;
      font-weight: $font-weight-normal;
      color: color('blue');
      text-align: justify;
    }

    p {
      margin-top: 1rem;
      font-family: $font-family-sans-serif;
      font-size: 0.875rem;
      font-weight: $font-weight-medium;
      color: color('blue');
    }
  }
}

q::before {
  display: none;
}

q::after {
  display: none;
}
