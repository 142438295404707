.content-page-header {
  $header-spacing: 40px;

  position: relative;
  width: 100%;
  background-size: cover;
  hyphens: auto;
  z-index: 1;

  @include screen-down('md') {
    margin-bottom: 0;
  }

  &__container {
    max-width: $content-area-extended-width;
    margin: 0 auto;

    @include screen-down(md) {
      margin: 0;
    }
  }

  &__bg-container {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    pointer-events: none;

    @include screen-down('md') {
      height: 200px;
    }
  }

  &__block {
    position: relative;
    bottom: -#{$header-spacing};
    padding: 3rem;
    padding-left: 10rem;
    width: 100%;
    max-width: 700px;
    overflow: hidden;

    // Default colors
    color: color('white');
    background-color: color('blue');

    .button {
      background-color: color('blue-dark');
      color: color('white');

      &--text {
        background-color: transparent;
        color: color('white');
      }
    }

    @include screen-down('lg') {
      max-width: 60%;
      padding-left: 3rem;
    }

    @include screen-down('md') {
      max-width: 100%;
      bottom: 0;
      padding: 1.5rem;
      flex-grow: 1;
    }

    &--blue {
      background-color: color('blue');

      .button {
        background-color: color('blue-dark');
        color: color('white');

        &--text {
          background-color: transparent;
          color: color('white');
        }
      }
    }

    &--green {
      background-color: color('green');

      .button {
        background-color: color('grey');
        color: color('black');

        &--text {
          background-color: transparent;
          color: color('black');
        }
      }
    }

    &--light-blue {
      background-color: color('blue-light');

      .button {
        background-color: color('blue');
        color: color('white');

        &--text {
          background-color: transparent;
          color: color('white');
        }
      }
    }
  }

  .search {
    min-height: 200px;
  }

  &__tag {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-medium;
    font-size: $header-tag;
    line-height: 1.25; // 22.5px
    color: color('white'); // default
    opacity: 1;

    &--blue {
      color: color('white');
    }

    &--green {
      color: color('blue-dark');
    }

    &--light-blue {
      color: color('black');
    }
  }

  &__title {
    font-size: 3.125rem; // 50px
    line-height: 1.16; // 58px
    position: relative;
    margin-top: 0;
    color: color('white'); // default
    opacity: 1;

    @include screen-down(md) {
      font-size: 2.5rem; // 40px
    }

    &--blue {
      color: color('white');
    }

    &--green {
      color: color('blue-dark');
    }

    &--light-blue {
      color: color('black');
    }
  }

  &__text {
    position: relative;
    color: inherit;

    p {
      opacity: 1;
    }

    @include screen-down('md') {
      font-size: 1.125rem;
    }

    ul,
    ol {
      list-style: initial; // fallback
      list-style: revert;
      padding-left: 2rem;
    }

    &--blue {
      color: color('white');
    }

    &--green {
      color: color('blue-dark');
    }

    &--light-blue {
      color: color('black');
    }
  }

  &--with-image {
    height: 600px;

    .content-page-header__block {
      position: absolute;
    }

    @include screen-down('md') {

      .content-page-header__block {
        position: relative;
      }

      display: flex;
      flex-direction: column;
      height: auto;
    }
  }
}
