.flockler-container {
  margin: 0 auto;
  width: 100%;
  max-width: $content-area-width;
}

.flockler {
  padding: 80px 0;
  min-height: 400px;
}
