.link {
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  line-height: $line-height-lg;

  &--blue {
    color: color('blue');

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: color('blue');
    }
  }

  &--grey {
    color: color('black');
    opacity: 0.68;
    font-weight: $font-weight-normal;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: color('black');
      opacity: 1;
    }
  }

  &--white {
    color: color('white');
    font-weight: $font-weight-normal;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: color('white');
      opacity: 1;
    }
  }
}
