.page-header {
  .language-selector {
    display: flex;
    text-transform: uppercase;
    margin: 0;

    &__item {
      padding: 0 1rem;

      &:first-child {
        padding-left: 0;
      }

      &:not(:first-child) {
        border-left: solid 1px color('blue-light');
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &__link {
      color: color('black');
      font-weight: normal;
      text-decoration: none;

      &--active {
        color: color('black');
        text-decoration: underline;
      }
    }
  }

  &--light,
  &--blue-light,
  &--grey,
  &--green {
    .main-menu--transparent .language-selector__item:not(:first-child) {
      border-left: solid 1px color('black');
    }
  }
}
