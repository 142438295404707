@function color($name) {
  $ti8m-colors: (
    'blue': #0F68FF,
    'blue-light': #EEF3F5,
    'blue-dark': #002650,
    'green': #00E6C8,
    'white': #FFFFFF,
    'grey': #FAFAFA,
    'black': #000000
  );

  @if map-has-key($ti8m-colors, $name) {
    @return map-get($ti8m-colors, $name);
  }

  @error "Missing color '#{$name}'";
}

$ti8m-gradient: linear-gradient(141.12deg, color('blue') 22.32%, color('green') 83.26%);
$ti8m-gradient-mobile: linear-gradient(100deg, color('blue') 0%, color('green') 90%);

$bg-colors: (
  'white': color('white'),
  'grey': color('grey'),
  'blue-light': color('blue-light'),
  'blue-dark': color('blue-dark'),
  'gradient': $ti8m-gradient
);
