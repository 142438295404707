.contacts-viewer {
  display: block;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .nav-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid color('blue-light');
    flex-wrap: wrap;

    a {
      text-decoration: none;
    }
  }

  &__title {
    text-align: center;
    font-weight: $font-weight-medium;
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }

  &__pill {
    font-weight: 400;
    font-size: 1rem;
    color: color('black');
    margin-right: 1.5rem;
    padding-bottom: 2px;

    a {
      text-decoration: none;
    }
  }

  &__pill:hover {
    text-decoration: none;
    color: unset;
  }

  &__pill.active {
    border-bottom: 3px solid color('blue');
    font-weight: $font-weight-medium;
  }

  .tab-content > .tab-pane {
    display: none;
  }

  .tab-content {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include screen-down(md) {
      padding-top: unset;
      padding-bottom: unset;
    }
  }

  .tab-content > .active {
    display: block;
  }

  .contacts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0.5rem;

    > * {
      width: 33%;

      @include screen-down(md) {
        width: 50%;
      }

      @include screen-down(sm) {
        width: 100%;
      }
    }
  }
}

.contact-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;

  &__image-container {
    background: color('grey');
    border-radius: 100%;
    overflow: hidden;
    display: grid;
    place-items: center;
    width: 240px;
    height: 240px;
  }

  &__image {
    width: 100%;
  }

  &__name {
    color: color('black');
    font-size: 1.25rem;
  }

  &__description {
    color: rgba(color('black'), 1);
  }

  &__link {
    color: rgba(color('black'), 1);
  }
}
