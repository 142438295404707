.button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  font-weight: $font-weight-medium;
  background-color: color('blue');
  color: color('white');
  border-radius: 3px;
  border: none;
  transition: box-shadow 200ms;
  text-decoration: none;
  text-align: left;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  > * {
    vertical-align: middle;
  }

  &__icon {
    margin-right: 1em;
    width: auto;
    height: 1.5em;
  }

  &__text {
    display: flex;
    flex-direction: column;

    &::after {
      content: '';
      display: none;
      width: 0;
      height: 4px;
      background-color: color('blue');
      transition: width 200ms ease-out;
    }
  }

  &:hover {
    box-shadow: $ti8m-button-shadow;
    cursor: pointer;
    text-decoration: none;
    color: color('white');
  }

  &--primary {
    padding: 1em 2em;
  }

  &--secondary {
    padding: 0.625em 2em;
  }

  &--text {
    background-color: transparent;
    color: color('black');

    .page-teaser-container--alternate-bg & {
      padding: 0 0 0 28px;
      background-position: left 1px;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAnIGhlaWdodD0nMjAnIHZpZXdCb3g9JzAgMCAyMCAyMCcgZmlsbD0nbm9uZScgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAuMDAwMyAxOC45NTc3QzUuMDU4NjYgMTguOTU3NyAxLjA0MTk5IDE0Ljk0MSAxLjA0MTk5IDkuOTk5MzVDMS4wNDE5OSA1LjA1NzY4IDUuMDU4NjYgMS4wNDEwMiAxMC4wMDAzIDEuMDQxMDJDMTQuOTQyIDEuMDQxMDIgMTguOTU4NyA1LjA1NzY4IDE4Ljk1ODcgOS45OTkzNUMxOC45NTg3IDE0Ljk0MSAxNC45NDIgMTguOTU3NyAxMC4wMDAzIDE4Ljk1NzdaTTEwLjAwMDMgMi4yOTEwMkM1Ljc1MDMzIDIuMjkxMDIgMi4yOTE5OSA1Ljc0OTM1IDIuMjkxOTkgOS45OTkzNUMyLjI5MTk5IDE0LjI0OTMgNS43NTAzMyAxNy43MDc3IDEwLjAwMDMgMTcuNzA3N0MxNC4yNTAzIDE3LjcwNzcgMTcuNzA4NyAxNC4yNDkzIDE3LjcwODcgOS45OTkzNUMxNy43MDg3IDUuNzQ5MzUgMTQuMjUwMyAyLjI5MTAyIDEwLjAwMDMgMi4yOTEwMlonIGZpbGw9JyMwRjY4RkYnLz48cGF0aCBkPSdNMTIuMDgzNyAxMC42MjQzSDcuMDgzNjZDNi43NDE5OSAxMC42MjQzIDYuNDU4NjYgMTAuMzQxIDYuNDU4NjYgOS45OTkzNUM2LjQ1ODY2IDkuNjU3NjggNi43NDE5OSA5LjM3NDM1IDcuMDgzNjYgOS4zNzQzNUgxMi4wODM3QzEyLjQyNTMgOS4zNzQzNSAxMi43MDg3IDkuNjU3NjggMTIuNzA4NyA5Ljk5OTM1QzEyLjcwODcgMTAuMzQxIDEyLjQyNTMgMTAuNjI0MyAxMi4wODM3IDEwLjYyNDNaJyBmaWxsPSdibGFjaycvPjxwYXRoIGQ9J00xMC40MTY2IDEzLjEyNDNDMTAuMjU4MiAxMy4xMjQzIDEwLjA5OTkgMTMuMDY1OSA5Ljk3NDkxIDEyLjk0MDlDOS43MzMyNCAxMi42OTkzIDkuNzMzMjQgMTIuMjk5MyA5Ljk3NDkxIDEyLjA1NzZMMTIuMDMzMiA5Ljk5OTI3TDkuOTc0OTEgNy45NDA5N0M5LjczMzI0IDcuNjk5MyA5LjczMzI0IDcuMjk5MyA5Ljk3NDkxIDcuMDU3NjNDMTAuMjE2NiA2LjgxNTk3IDEwLjYxNjYgNi44MTU5NyAxMC44NTgyIDcuMDU3NjNMMTMuMzU4MiA5LjU1NzZDMTMuNTk5OSA5Ljc5OTI3IDEzLjU5OTkgMTAuMTk5MyAxMy4zNTgyIDEwLjQ0MDlMMTAuODU4MiAxMi45NDA5QzEwLjczMzIgMTMuMDY1OSAxMC41NzQ5IDEzLjEyNDMgMTAuNDE2NiAxMy4xMjQzWicgZmlsbD0nIzBGNjhGRicvPjwvc3ZnPg==');
    }

    .button__text::after {
      display: inline-block;
    }

    &:hover {
      color: color('black');
      box-shadow: none;

      .button__text::after {
        width: 100%;
      }
    }

    .card--link-container & {
      background-color: transparent;

      .button__text::after {
        display: inline-block;
      }
    }

    .card--link-container .card__content:hover & {
      box-shadow: none;

      .button__text.notquicklink::after {
        width: 100%;
      }
    }
  }

  &--outline {
    color: color('blue');
    border: solid 1px color('blue');
    background-color: transparent;
    padding: 4px 10px;
    border-radius: 2px;
    font-size: 0.875rem;
    font-weight: 400;

    &:hover,
    &.active {
      color: color('white');
      background-color: color('blue');
    }

    &:disabled {
      border-color: gray;
      color: gray;
      background-color: transparent;

      &.active {
        background-color: gray;
        color: color('white');
      }
    }
  }

  &:disabled {
    cursor: initial;

    .button__text::after {
      width: 0;
    }
  }
}
