.dynamics-content {

  .event-registration {

    .field-container {
      padding: 0;
    }

    .form-group {
      margin-bottom: 1.8rem;
    }

    /* stylelint-disable */
    input[type='checkbox'] {
      vertical-align: middle;

      & + span {
        font-size: $font-size-sm;
        vertical-align: middle;

        a {
          font-size: $font-size-sm;
        }
      }
    }
    /* stylelint-enable */
  }
}
