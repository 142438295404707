/* stylelint-disable */
#blog-page {

  .blog-page-header {
    hyphens: none;

    &__title {
      font-size: 3.625rem;
      margin: 0 0 $margin-5;
    }

    &__abstract, &__abstract * {
      font-size: 1.5rem;
      font-weight: 300;
      margin: 0 0 $margin-4;
      opacity: 1;
    }

    &__image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 480px;
      margin-bottom: 80px;
      overflow: hidden;

      @include screen-down(md) {
        margin-bottom: 40px;
      }
    }

    &__image {
      width: 100%;
      max-width: 100%;
    }

    @include screen-down(md) {
      &__title {
        font-size: 2rem;
      }

      &__abstract {
        font-size: 1.25rem;
      }
    }
  }

  .blog-sidebar {

    &__title {
      color: color('black');
      font-family: $font-family-monospace;
      margin-bottom: 0.5em;
    }

    &__share-links {

      a {
        display: inline-block;
        margin-right: 1rem;
      }

      img {
        filter: brightness(0.5);
      }
    }
  }

  .blog-content {
    hyphens: none;

    img {
      width: 100% !important;
      max-width: 100%;
    }

    .c-text__title {
      margin-top: 2rem;
      font-size: 1.5rem;
    }

    .c-text img {
      height: auto !important;
    }

    .c-text a {
      text-decoration-color: color('blue');
      text-decoration-thickness: 2px;
    }

    .section,
    .c-text__wrapper {
      padding: 0;
    }

    .section__container {

      .container {
        padding: 0;
      }
    }

    .quote {
      padding: 24px 0;
    }

    .gallery {
      grid-template-columns: repeat(3, 1fr);

      @include screen-down(md) {
        grid-template-columns: 1fr 1fr;
      }

      > * {
        grid-area: span 1 / span 1;
      }
    }

    @include screen-up(lg) {
      .slider-container {
        width: 180%;
        max-width: 100vw;
        margin-left: -45%;
      }
    }

    .image-section {
      margin-bottom: 80px;
    }

    .video-container {
      margin-bottom: 80px;

      video, iframe {
        width: 100%;
      }
    }
  }

  .blog-related-posts {
    margin: 80px auto;

    @include screen-down(md) {
      margin: 40px auto;
    }
  }
}
