// Onetrust cookie consent style overrides
#onetrust-button-group button {
  border: none !important;
}

#onetrust-pc-btn-handler {
  background-color: transparent !important;
  color: color('blue') !important;
}

#onetrust-reject-all-handler {
  background-color: color('blue') !important;
  color: color('white') !important;
}

#onetrust-accept-btn-handler {
  background-color: color('blue') !important;
  color: color('white') !important;
}

#onetrust-policy-text {
  color: color('white') !important;
  opacity: 1 !important;
  font-size: 1rem !important;
}

.ot-pc-footer-logo {
  // this hides the "powered by Onetrust"
  display: none !important;
}

.ot-floating-button {
  display: none !important;
}
