.nav {
  padding: 0 2.5rem;
  display: flex;
  font-size: 1.125rem;
  font-weight: $font-weight-medium;

  @include screen-down(md) {
    padding: 0 24px;
  }

  button {
    border: none;
    background: none;
    appearance: none;
    -webkit-appearance: none;
    font-family: $font-family-base;
    font-weight: $font-weight-medium;

    @include screen-down(md) {
      color: color('blue');
    }
  }

  > * {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__logo {
    margin-right: auto;

    img, svg {
      height: 40px;

      @include screen-down(md) {
        height: 32px;
      }
    }
  }

  &__links-list {
    display: flex;
    height: inherit;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @include screen-down(md) {
      display: none;
    }

    &-item {
      position: relative;
      margin: 0 1.25rem;
      display: flex;
      align-items: center;
      height: inherit;
      cursor: pointer;

      @include screen-down(lg) {
        margin: 0 1rem;
      }

      > * {
        display: flex;
        align-items: center;
        height: inherit;
      }

      .js-nav-button::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        content: '';
        background-color: transparent;
      }

      .js-nav-button:hover::after,
      .js-nav-button.is-active::after {
        background-color: color('blue');
      }
    }

    a {
      color: color('black');
      text-decoration: none;
    }
  }

  &__hamburger-button {
    display: none;
    margin-left: 1rem;

    @include screen-down(md) {
      display: inline-block;
    }

    .svg-icon--close {
      display: none;
    }
  }
}
