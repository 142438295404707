#content-page {

  .page-header {
    display: block;
  }

  .page-content {
    display: block;
    width: 100%;
    min-height: 50vh;
    margin: 0 auto;
  }
}
