.page-header {
  .quick-links {
    display: flex;
    margin: 0;

    > * {
      padding: 0 1em;
    }

    &--vertical {
      flex-direction: column;

      > * {
        padding: 1em 0 0 0;

        &:first-child {
          padding-top: 0;
        }
      }
    }

    a {
      color: color('black');
      font-weight: normal;
      text-decoration: none;
    }
  }

  &--light,
  &--blue-light,
  &--grey {

    .main-menu {
      .main-menu__meta-nav {
        * {
          color: color('black');
        }
      }

      &--transparent {
        .main-menu__meta-nav .quick-links a {
          &:hover {
            text-decoration: underline solid 2px color('blue-light');
          }
        }
      }
    }
  }

  &--green {
    .main-menu {
      .main-menu__meta-nav {
        * {
          color: color('black');
        }

        .main-menu__meta-nav a {
          &:hover {
            text-decoration: underline solid 2px color('black');
          }
        }
      }

      &--transparent {
        .main-menu__meta-nav .quick-links a {
          &:hover {
            text-decoration: underline solid 2px color('black');
          }
        }
      }

    }
  }

  &--blue {
    .main-menu {
      &--transparent {
        .main-menu__meta-nav .quick-links a {
          color: color('white');
          &:hover {
            text-decoration: underline solid 2px color('white');
          }
        }
      }
    }
  }
}


