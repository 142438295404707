#blog-root-page {

  .blog-root {

    &__filters {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;
    }

    &__first-post {
      margin-top: 5rem;
      margin-bottom: 10rem;

      .card {
        @include screen-up(md) {
          height: 350px;
        }

        &__text {
          overflow-y: auto;
        }
      }
    }

    &__posts-list {
      margin: 0 auto;

      .card {
        height: auto;
        margin: 0 0.5rem 1rem;
        width: calc(33% - 1rem);

        @include screen-down(lg) {
          width: calc(50% - 1.5rem);
        }

        @include screen-down(md) {
          width: auto;
          margin: 0 0 1rem;
        }
      }
    }
  }

  body {
    background-color: color('grey');
  }
}
