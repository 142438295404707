.text-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  margin: 2.5rem 2rem;

  &__title {
    padding: 0;
    margin: 0;
    font-weight: $font-weight-medium;
  }

  &__subtitle {
    padding: 0;
    margin: 0;
    font-size: $font-size-lg;
    text-overflow: clip;
  }

  &__image {
    flex-grow: 1;
    margin-bottom: 0.5rem;

    img {
      height: 80px;
      width: 80px;
    }
  }

  &__number {
    font-family: $font-family-sans-serif;
    color: color('blue');
    font-size: 4rem;
    line-height: 1.5;
    margin: 0;

    @include media-breakpoint-up('md') {
      position: relative;
      left: -0.2rem;
    }

    .section--bg-blue & {
      color: color('white');
    }

    .section--bg-green & {
      color: color('black');
    }
  }
}
