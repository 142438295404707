// Gallery

.gallery {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
  font-size: $font-size-lg;
  gap: 1em;
  padding: 0;
  margin: 0 auto 80px;
  max-width: $content-area-extended-width;

  &__title {
    font-weight: $font-weight-medium;
    font-size: 2rem;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  &__image {
    font-weight: bold;
    font-size: 4rem;
    position: relative;
    text-align: -webkit-match-parent;
  }

  img {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  :nth-child(7n+1) {
    grid-area: span 1 / span 1;
  }

  :nth-child(7n+2) {
    grid-area: span 2 / span 2;
  }

  :nth-child(7n+3) {
    grid-area: span 1 / span 1;
  }

  :nth-child(7n+4) {
    grid-area: span 2 / span 1;
  }

  :nth-child(7n+5) {
    grid-area: span 2 / span 1;
  }

  :nth-child(7n+6) {
    grid-area: span 1 / span 1;
  }

  :nth-child(7n) {
    grid-area: span 1 / span 1;
  }

  @include screen-down('md') {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }

  @include screen-down('sm') {
    grid-template-columns: repeat(2, 1fr);
  }
}

.sl-wrapper,
.sl-overlay {
  @include screen-down('sm') {
    display: none !important;
  }
}
