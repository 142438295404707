.pagination {
  flex-wrap: wrap;
  gap: 10px;

  .page-item {
    border: solid 1px color('blue');
    background-color: transparent;
    color: color('blue');
    border-radius: 2px;
  }

  .page-link {
    background-color: transparent;
    color: color('blue');
    border: none;
    text-decoration: none;
    margin: 0;
  }

  .page-item.disabled {
    border-color: grey;

    .page-link {
      background-color: transparent;
      color: grey;
    }
  }

  .page-item.active {

    .page-link {
      background-color: color('blue');
      color: color('white');
    }
  }
}
