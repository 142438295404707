@import 'start';
@import 'content';
@import 'blog-root';
@import 'blog';

.page-header {
  padding-top: $menu-bar-height;

  @include screen-down(md) {
    padding-top: $menu-bar-height-mobile;
  }
}
