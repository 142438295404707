$content-area-width: 1120px;
$content-area-extended-width: 1800px;

$menu-bar-height: 120px;
$menu-bar-height-mobile: 50px;

$ti8m-card-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.12), 0 8px 8px -4px rgba(0, 0, 0, 0.08);
$ti8m-button-shadow: #{$ti8m-card-shadow};

// spacing
$margin-1: 0.5rem;
$margin-2: 1rem;
$margin-3: 1.5rem;
$margin-4: 2rem;
$margin-5: 2.5rem;
